<div class="main-body-con">
  <div class="row" *ngIf="showError">
    <div class="col-12">
      <div class="alert alert-danger alert-dismissible fade show my-2" role="alert">
        <ul class="view-error">
          <li *ngFor="let err of errorList">{{ err }}</li>
        </ul>
        <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
          aria-label="Close"></button>
      </div>
    </div>
  </div>
  <div class="page-container pt-4">
    <div class="page-hdr-outer px-4">
      <div class="page-hdr pb-0">
        <!-- <h2>Manage List</h2> -->
        <!-- <div class="sel-col-text"> -->
        <div class="filterdiv1 flex-div justify-content-between">
          <div class="tbl-top-actions mb-2">
            <div class="flex-div filter-list-comp">
              <label class="me-3">Filter by</label>
              <select class="select-cs form-select" [(ngModel)]="saveAsSelectedId"
                (change)="onFilterDropdownChange($event)">
                <option *ngFor="let filter of filterDropdownList" [value]="filter.id" [selected]="filter.id==1">
                  {{ filter.listName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- </div> -->
        <div class="flex-div dropdown list-link">
          <div class="dropdown reset me-3">

            <a href="javascript:void(0)" class="dropdown-toggle" id="dropdownMenuButton3" data-bs-toggle="dropdown"
              data-bs-auto-close="outside" style="color: #122B74 !important;cursor:pointer  !important;"><label
                class="ps-2"><img src="assets/images/reset.png" class="me-2 import-img" width="13px"
                  alt="" />Reset</label></a>

            <div class="dropdown-menu reset-menu-div" aria-labelledby="dropdownMenuButton3">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="resetAll"
                  (change)="onChangeResetAll($event)">
                <label class="form-check-label" for="resetAll">
                  Reset All
                </label>
              </div>
              <!-- <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="filter"
                  [(ngModel)]="selectResetArr['filter']">
                <label class="form-check-label" for="filter">
                  Filter
                </label>
              </div> -->

              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="column"
                  [(ngModel)]="selectResetArr['column']">
                <label class="form-check-label" for="column">
                  Column
                </label>
              </div>

              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="sorting"
                  [(ngModel)]="selectResetArr['sorting']">
                <label class="form-check-label" for="sorting">
                  Sorting
                </label>
              </div>

              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="columnFilter"
                  [(ngModel)]="selectResetArr['columnFilter']">
                <label class="form-check-label" for="columnFilter">
                  Column Filter
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="width"
                  [(ngModel)]="selectResetArr['width']">
                <label class="form-check-label" for="width">
                  Column Width
                </label>
              </div>

              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="columnSequence"
                  [(ngModel)]="selectResetArr['columnSequence']">
                <label class="form-check-label" for="columnSequence">
                  Column Sequence
                </label>
              </div>

              <div class="flex-div"><button class="btn btn-outline-primary me-3"
                  (click)="selectResetArr={};">Clear</button> <button class="btn btn-primary"
                  (click)="resetAllfunctionality()">Apply</button></div>
            </div>
          </div>

          <div class="dropdown export-dropdown me-3" *ngIf="isValidUser">
            <a href="javascript:void(0)" class="dropdown-toggle" id="dropdownMenuButton1"
              style="color: #122B74 !important;cursor:pointer  !important;" data-bs-toggle="dropdown"
              aria-expanded="false">
              <label class="ps-2"><img src="assets/images/import.svg" class="me-2 import-img" width="13px"
                  alt="" />Export</label>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <!-- <li (click)="DownloadExportFile('Selected')"><a href="javascript:void(0)"
                         [style.pointer-events]="mySelection.length==0 ? 'none' : 'auto'"
                                                [style.color]="mySelection.length==0 ? 'grey' : '#122B74 !important'"
                        style="color: #122B74 !important;cursor:pointer  !important;"
                        class="dropdown-item">Selected Contact</a></li> -->
              <li (click)="DownloadExportFile('All')"><a href="javascript:void(0)"
                  style="color: #122B74 !important;cursor:pointer  !important;" title="" class="dropdown-item">All
                  List</a></li>
            </ul>
          </div>
          <!-- <a class="px-4" href="javascript:void(0)" *ngIf="isValidUser" (click)="exportGlobal()"> <label
              class="ps-2"><img src="assets/images/file-export.svg" class="me-2 import-img" width="13px"
                alt=" " />Export</label></a> -->

          <a href="javascript:void(0)" class="dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown"
            aria-expanded="false">
            <span><img src="assets/images/plus.svg" alt="" /></span><label class="ps-2">Add List</label></a>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li><a href="javascript:void(0)" class="dropdown-item" title="" (click)="navigateToList('')">Prospects</a>
            </li>
            <li><a href="javascript:void(0)" class="dropdown-item" title=""
                (click)="navigateToContactList('')">Contacts</a></li>
            <li><a href="javascript:void(0)" class="dropdown-item" title=""
                (click)="navigateToWhiteBoardList('')">Whiteboard Activities</a></li>
          </ul>
          <!--  <a href="javascript:void(0)" [routerLink]="['../create-query']">
            <span><img src="assets/images/plus.svg" alt="" /></span><label class="ps-2">Create Dynamic List</label></a>-->
        </div>
      </div>
      <!-- <div class="sel-col-hd flex-div space-between px-0 mt-2"> -->
      <!-- <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
          <li class="nav-item">
            <button (click)="tabClick('Prospect')" class="nav-link active" id="prospect-tab" data-bs-toggle="tab"
              data-bs-target="#prospect" type="button" role="tab" aria-controls="prospect" aria-selected="true">
              Prospects
            </button>
          </li>

          <li class="nav-item">
            <button disabled="true" (click)="tabClick('Contact')" class="nav-link" id="contact-tab" data-bs-toggle="tab"
              data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
              Contacts
            </button>
          </li>

          <li class="nav-item">
            <button disabled="true" (click)="tabClick('Whiteboard')" class="nav-link" id="wba-tab" data-bs-toggle="tab"
              data-bs-target="#wba" type="button" role="tab" aria-controls="wba" aria-selected="false">
              Whiteboard Activities
            </button>
          </li>
        </ul> -->
      <!-- <div *ngIf="toggleListButton == 'Prospect'" class="dropdown createlist">
          <button
            class="btn btn-primary btn-pd dropdown-toggle me-2"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Create Prospect List
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a href="javascript:void(0)" (click)="navigateToList('')">
                <label class="ps-1">Static </label></a
              >
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['../create-query']">
                <label class="ps-1">Dynamic </label></a
              >
            </li>
          </ul>
        </div>-->
      <!-- <div *ngIf="toggleListButton == 'Contact'" class="dropdown createlist">
          <button
            class="btn btn-primary btn-pd dropdown-toggle me-4"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Create Contact List
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a
                href="javascript:void(0)"
                (click)="navigateToList('contact-list')"
                class="me-4"
              >
                <label class="ps-1">Static </label></a
              >
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['../create-query']">
                <label class="ps-1">Dynamic </label></a
              >
            </li>
          </ul>
        </div> -->
      <!-- <div
          *ngIf="toggleListButton == 'Whiteboard'"
          class="dropdown createlist"
        >
          <button
            class="btn btn-primary btn-pd dropdown-toggle me-4"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Create Whiteboard List
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a
                href="javascript:void(0)"
                (click)="navigateToList('create-whiteboard')"
                class="me-4"
              >
                <label class="ps-1">Static </label></a
              >
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['../create-query']">
                <label class="ps-1">Dynamic </label></a
              >
            </li>
          </ul>
        </div> -->
      <!-- </div> -->
      <div class="grid-con">
        <div class="grid__wrapper wrp-pros">
          <kendo-grid [reorderable]="true" width="100%" [resizable]="true" filterable="menu"
            (filterChange)="filterChange($event)" [data]="FinalProspectList" [sortable]="true" [skip]="skip"
            [filter]="gridFilter" [pageable]="{ pageSizes: true, position: 'top' }" [pageSize]="pageSize" [sortable]="{
            allowUnsort: false,
            mode: multiple ? 'multiple' : 'single'
          }" [sort]="sort" (sortChange)="sortChange($event)" (pageChange)="prospectPaginate($event)"
            (columnVisibilityChange)="onColumnMenuInit($event)" #grid (columnReorder)="onColumnReorder($event)"
            (columnResize)="columnResize($event)">
            <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
              <div class="flex-div justify-content-between grid-hd">
                <div class="flex-div">
                  <kendo-textbox placeholder="Search List Name ..." #searchText
                    [ngModel]="filterData ? filterData.searchText : filterDataEmpty" (ngModelChange)="filterData ? filterData.searchText : filterDataEmpty = $event"
                    (input)="searchValue(searchText.value)" [style.width.px]="205"></kendo-textbox><img
                    src="assets/images/search.svg" (click)="onFilter()" alt="" class="ms-2" />
                </div>
                <div class="flex-div">
                  <div class="flex-div">
                    <div class="flex-div">
                      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                      <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                      <kendo-pager-next-buttons></kendo-pager-next-buttons>
                      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
                      <kendo-grid-messages pagerItems="Items" [pagerItemsPerPage]="'Items per page'">
                      </kendo-grid-messages>
                    </div>
                    <kendo-pager-info class="bd-l"></kendo-pager-info>
                  </div>
                  <button [ngClass]="{ actbtn: isFilter }" class="filter-button-choose" (click)="setStyleOfPopup()">
                    <kendo-grid-column-chooser [allowHideAll]="false"></kendo-grid-column-chooser>
                  </button>
                </div>
              </div>

            </ng-template>
            <kendo-grid-column title="Actions" [reorderable]="false" [resizable]="false" [width]="70" field="Actions"
              [filterable]="false" [sortable]="false" [includeInChooser]="false" class="overflow-visible">
              <ng-template kendoGridCellTemplate let-rowData>
                <a *ngIf="rowData.creationStatus == 'Published' && rowData.listEntityType == 'Prospect'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <!-- Published -->
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/list-management/preview-list'"
                      [queryParams]="{listId: rowData.id}"><img src="../../assets/images/eye.svg"
                        alt="Go to Prospect" /><span>View List
                        Detail</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="exportFile(rowData.id, rowData.listEntityType)"><img src="../../assets/images/excel.svg"
                        alt="Export" /><span>Export List</span></a>
                  </li>
                  <li>
                    <a *ngIf="rowData.creationStatus == 'Published' && rowData.listType == 'QUERY'"
                      class="dropdown-item" href="javascript:void(0)" (click)="navigateToList(rowData)"><img
                        src="../../assets/images/edit2.svg" alt="Edit" />
                      <span>Edit</span></a>
                  </li>
                </ul>
                <a *ngIf="rowData.creationStatus == 'Published' && rowData.listEntityType == 'Contact'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      [routerLink]="'/list-management/preview-list-contact'" [queryParams]="{listId: rowData.id}"><img
                        src="../../assets/images/eye.svg" alt="Go to Prospect" /><span>View List Detail</span></a>
                    <!-- (click)="navigatetoViewContactList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="exportFile(rowData.id, rowData.listEntityType)"><img src="../../assets/images/excel.svg"
                        alt="Export" /><span>Export List</span></a>
                  </li>
                  <li>
                    <a *ngIf="rowData.creationStatus == 'Published' && rowData.listType == 'QUERY'"
                      class="dropdown-item" href="javascript:void(0)" (click)="navigateToContactList(rowData)"><img
                        src="../../assets/images/edit2.svg" alt="Edit" />
                      <span>Edit</span></a>
                  </li>
                </ul>
                <a *ngIf="rowData.creationStatus == 'Published' && rowData.listEntityType == 'WBA'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      [routerLink]="'/list-management/preview-list-whiteboard'"
                      [queryParams]="{listId: rowData.id}"><img src="../../assets/images/eye.svg"
                        alt="Go to Prospect" /><span>View List Detail</span></a>
                    <!-- (click)="navigatetoViewWbaList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="exportFile(rowData.id, rowData.listEntityType)"><img src="../../assets/images/excel.svg"
                        alt="Export" /><span>Export List</span></a>
                  </li>
                  <li>
                    <a *ngIf="rowData.creationStatus == 'Published' && rowData.listType == 'QUERY'"
                      class="dropdown-item" href="javascript:void(0)" (click)="navigateToWhiteBoardList(rowData)"><img
                        src="../../assets/images/edit2.svg" alt="Edit" />
                      <span>Edit</span></a>
                  </li>
                </ul>
                <!-- Draft -->
                <a *ngIf="rowData.creationStatus == 'Draft' && rowData.listEntityType == 'Prospect'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/list-management/preview-list'"
                      [queryParams]="{listId: rowData.id}"><img src="../../assets/images/eye.svg"
                        alt="Go to Prospect" /><span>View List
                        Detail</span></a>
                    <!-- (click)="navigatetoViewList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="navigateToList(rowData)"><img
                        src="../../assets/images/edit2.svg" alt="Edit Prospect" />
                      <span>Edit</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="cancel(rowData.id,rowData.listEntityType)"><img src="../../assets/images/trash.svg"
                        alt="Delete List" /><span>Delete</span></a>
                  </li>
                </ul>
                <a *ngIf="rowData.creationStatus == 'Draft' && rowData.listEntityType == 'Contact'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      [routerLink]="'/list-management/preview-list-contact'" [queryParams]="{listId: rowData.id}"><img
                        src="../../assets/images/eye.svg" alt="Go to Prospect" /><span>View List Detail</span></a>
                    <!-- (click)="navigatetoViewContactList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="navigateToContactList(rowData)"><img
                        src="../../assets/images/edit2.svg" alt="Edit Prospect" />
                      <span>Edit</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="cancel(rowData.id,rowData.listEntityType)"><img src="../../assets/images/trash.svg"
                        alt="Delete List" /><span>Delete</span></a>
                  </li>
                </ul>
                <a *ngIf="rowData.creationStatus == 'Draft' && rowData.listEntityType == 'WBA'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      [routerLink]="'/list-management/preview-list-whiteboard'"
                      [queryParams]="{listId: rowData.id}"><img src="../../assets/images/eye.svg"
                        alt="Go to Prospect" /><span>View List Detail</span></a>
                    <!-- (click)="navigatetoViewWbaList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="navigateToWhiteBoardList(rowData)"><img
                        src="../../assets/images/edit2.svg" alt="Edit Prospect" />
                      <span>Edit</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="cancel(rowData.id,rowData.listEntityType)"><img src="../../assets/images/trash.svg"
                        alt="Delete List" /><span>Delete</span></a>
                  </li>
                </ul>
                <!-- Template -->
                <a *ngIf="rowData.creationStatus == 'Template' && rowData.listEntityType == 'Prospect'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/list-management/preview-list'"
                      [queryParams]="{listId: rowData.id}"><img src="../../assets/images/eye.svg"
                        alt="Go to Prospect" /><span>View List
                        Detail</span></a>
                    <!-- (click)="navigatetoViewList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="navigateToList(rowData)"><img
                        src="../../assets/images/edit2.svg" alt="Delete List" /><span>Edit</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="cancel(rowData.id,rowData.listEntityType)"><img src="../../assets/images/trash.svg"
                        alt="Share List" /><span>Delete</span></a>
                  </li>
                </ul>
                <a *ngIf="rowData.creationStatus == 'Template' && rowData.listEntityType == 'Contact'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      [routerLink]="'/list-management/preview-list-contact'" [queryParams]="{listId: rowData.id}"><img
                        src="../../assets/images/eye.svg" alt="Go to Prospect" /><span>View List Detail</span></a>
                    <!-- (click)="navigatetoViewContactList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="navigateToContactList(rowData)"><img
                        src="../../assets/images/edit2.svg" alt="Delete List" /><span>Edit</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="cancel(rowData.id,rowData.listEntityType)"><img src="../../assets/images/trash.svg"
                        alt="Share List" /><span>Delete</span></a>
                  </li>
                </ul>
                <a *ngIf="rowData.creationStatus == 'Template' && rowData.listEntityType == 'WBA'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      [routerLink]="'/list-management/preview-list-whiteboard'"
                      [queryParams]="{listId: rowData.id}"><img src="../../assets/images/eye.svg"
                        alt="Go to Prospect" /><span>View List Detail</span></a>
                    <!-- (click)="navigatetoViewWbaList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="navigateToWhiteBoardList(rowData)"><img
                        src="../../assets/images/edit2.svg" alt="Delete List" /><span>Edit</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="cancel(rowData.id,rowData.listEntityType)"><img src="../../assets/images/trash.svg"
                        alt="Share List" /><span>Delete</span></a>
                  </li>
                </ul>
                <!-- System-Default -->
                <a *ngIf="rowData.creationStatus == 'System-Default' && rowData.listEntityType == 'Prospect'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/list-management/preview-list'"
                      [queryParams]="{listId: rowData.id}"><img src="../../assets/images/eye.svg"
                        alt="Go to Prospect" /><span>View List
                        Detail</span></a>
                    <!-- (click)="navigatetoViewList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="exportFile(rowData.id, rowData.listEntityType)"><img src="../../assets/images/excel.svg"
                        alt="Export List" />
                      <span>Export List</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="cancel(rowData.id,rowData.listEntityType)"><img src="../../assets/images/trash.svg"
                        alt="Delete List" /><span>Delete</span></a>
                  </li>

                </ul>
                <a *ngIf="rowData.creationStatus == 'System-Default' && rowData.listEntityType == 'Contact'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      [routerLink]="'/list-management/preview-list-contact'" [queryParams]="{listId: rowData.id}"><img
                        src="../../assets/images/eye.svg" alt="Go to Prospect" /><span>View List Detail</span></a>
                    <!-- (click)="navigatetoViewContactList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="exportFile(rowData.id, rowData.listEntityType)"><img src="../../assets/images/excel.svg"
                        alt="Export List" />
                      <span>Export List</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="cancel(rowData.id,rowData.listEntityType)"><img src="../../assets/images/trash.svg"
                        alt="Delete List" /><span>Delete</span></a>
                  </li>

                </ul>
                <a *ngIf="rowData.creationStatus == 'System-Default' && rowData.listEntityType == 'WBA'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      [routerLink]="'/list-management/preview-list-whiteboard'"
                      [queryParams]="{listId: rowData.id}"><img src="../../assets/images/eye.svg"
                        alt="Go to Prospect" /><span>View List Detail</span></a>
                    <!-- (click)="navigatetoViewWbaList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="exportFile(rowData.id, rowData.listEntityType)"><img src="../../assets/images/excel.svg"
                        alt="Export List" />
                      <span>Export List</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="cancel(rowData.id,rowData.listEntityType)"><img src="../../assets/images/trash.svg"
                        alt="Delete List" /><span>Delete</span></a>
                  </li>

                </ul>
                <!-- Marketing_List -->
                <a *ngIf="rowData.creationStatus == 'Marketing-List' && rowData.listEntityType == 'Contact'"
                  href="javascript:void(0)" title="" class="tbl-act pointer" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      [routerLink]="'/list-management/preview-list-contact'" [queryParams]="{listId: rowData.id}"><img
                        src="../../assets/images/eye.svg" alt="Go to Prospect" /><span>View List Detail</span></a>
                    <!-- (click)="navigatetoViewContactList(rowData.id)" -->
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="exportFile(rowData.id, rowData.listEntityType)"><img src="../../assets/images/excel.svg"
                        alt="Export List" />
                      <span>Export List</span></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)"
                      (click)="cancel(rowData.id,rowData.listEntityType)"><img src="../../assets/images/trash.svg"
                        alt="Delete List" /><span>Delete</span></a>
                  </li>

                </ul>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="listName" title="Name" [width]="GridColumnWidth('Name')"
              [hidden]="isGridColumnVisible('Name')">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cr-wd">
                  <a *ngIf="dataItem.listEntityType =='Prospect'" href="javascript:void(0)" title=""
                    pTooltip="{{ dataItem.listName }}" tooltipPosition="bottom"
                    [routerLink]="'/list-management/preview-list'" [queryParams]="{listId: dataItem.id}"
                    tooltipStyleClass="tooltipstyle">{{ dataItem.listName
                    }}
                  </a>
                  <a *ngIf="dataItem.listEntityType =='Contact'" href="javascript:void(0)" title=""
                    pTooltip="{{ dataItem.listName }}" tooltipPosition="bottom"
                    [routerLink]="'/list-management/preview-list-contact'" [queryParams]="{listId: dataItem.id}"
                    tooltipStyleClass="tooltipstyle">{{
                    dataItem.listName }}
                  </a>
                  <a *ngIf="dataItem.listEntityType =='WBA'" href="javascript:void(0)" title=""
                    pTooltip="{{ dataItem.listName }}" tooltipPosition="bottom"
                    [routerLink]="'/list-management/preview-list-whiteboard'" [queryParams]="{listId: dataItem.id}"
                    tooltipStyleClass="tooltipstyle">{{
                    dataItem.listName }}
                  </a>
                </div>
              </ng-template>
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
                  operator="startswith">
                </kendo-grid-string-filter-menu>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [hidden]="isGridColumnVisible('Entity Type')" field="listEntityType" title="Entity Type"
              [width]="GridColumnWidth('Entity Type')">
              <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFilterArrayListEntityType" [autoClose]="false"
                  [data]="listType" [valuePrimitive]="true" (valueChange)="onlistEntityChange($event)">
                </kendo-multiselect>
              </ng-template> -->
              <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                let-filterService="filterService">
                <multicheck-filter [data]="listType" [currentFilter]="filter" [filterService]="filterService"
                  [isPrimitive]="true" [field]="column.field"></multicheck-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [hidden]="isGridColumnVisible('Type')" field="listType" title="Type" filter="boolean"
              [width]="GridColumnWidth('Type')">
              <kendo-grid-messages filterIsTrue="Static" filterIsFalse="Dynamic">
              </kendo-grid-messages>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div *ngIf="dataItem.listType == 'STATIC'; else elseBlock">
                  Static
                </div>
                <ng-template #elseBlock>
                  <div #elseBlock>Dynamic</div>
                </ng-template>
              </ng-template>

            </kendo-grid-column>
            <kendo-grid-column [hidden]="isGridColumnVisible('Expiration Date')" field="expirationDate"
              title="Expiration Date" filter="date" format="{0:d}" [width]="GridColumnWidth('Expiration Date')">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.expirationDate | date: "MM/dd/yyyy" }}
              </ng-template>
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <date-range-filter [field]="column.field" [filter]="filter" [filterService]="filterService">
                </date-range-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [hidden]="isGridColumnVisible('Status')" [width]="GridColumnWidth('Status')"
              field="creationStatus" title="Status">
              <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFilterArrayStatus" [autoClose]="false"
                  [data]="statusOption" [valuePrimitive]="true" (valueChange)="onlistStatusChange($event)">
                </kendo-multiselect>
              </ng-template> -->
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cr-wd rm-style">
                  <a title="" pTooltip="{{dataItem.creationStatus}}" tooltipPosition="bottom"
                    tooltipStyleClass="tooltipstyle">{{dataItem.creationStatus}}</a>
                </div>
              </ng-template>
              <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                let-filterService="filterService">
                <multicheck-filter [data]="statusOption" [currentFilter]="filter" [filterService]="filterService"
                  [isPrimitive]="true" [field]="column.field"></multicheck-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [hidden]="isGridColumnVisible('List Owner')" [width]="GridColumnWidth('List Owner')"
              field="listCreatedBy" title="List Owner">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="cr-wd rm-style">
                  <a title="" pTooltip="{{dataItem.listCreatedBy}}" tooltipPosition="bottom"
                    tooltipStyleClass="tooltipstyle">{{dataItem.listCreatedBy}}</a>
                </div>
              </ng-template>
              <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                let-filterService="filterService">
                <multicheck-filter [data]="userList" [currentFilter]="filter" [filterService]="filterService"
                  [isPrimitive]="false" [field]="column.field" textField="display_name"
                  valueField="display_name"></multicheck-filter>
              </ng-template>

            </kendo-grid-column>
            <kendo-grid-column [hidden]="isGridColumnVisible('Created Date')" field="createdDate" title="Created Date"
              filter="date" format="{0:d}" [width]="GridColumnWidth('Created Date')">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.createdDate | date: "MM/dd/yyyy" }}
              </ng-template>
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <date-range-filter [field]="column.field" [filter]="filter" [filterService]="filterService">
                </date-range-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [hidden]="isGridColumnVisible('Last Modified Date')" field="lastModifiedDate"
              title="Last Modified Date" filter="date" format="{0:d}" [width]="GridColumnWidth('Last Modified Date')">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.lastModifiedDate | date: "MM/dd/yyyy" }}
              </ng-template>
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <date-range-filter [field]="column.field" [filter]="filter" [filterService]="filterService">
                </date-range-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [hidden]="isGridColumnVisible('Total Count')" field="totalCount" title="Total Count"
              filter="numeric" format="n0" class="text-right" [width]="GridColumnWidth('Total Count')">
              <ng-template kendoGridCellTemplate let-rowData>
                <div>
                  {{ rowData.totalCount }}
                </div>
              </ng-template>
              <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                <number-range-filter [field]="column.field" [filter]="filter" [filterService]="filterService"
                  [format]="'n0'">
                </number-range-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [filterable]="false" [hidden]="isGridColumnVisible('Row Count')"
              [width]="GridColumnWidth('Row Count')" title="Row Count">
              <ng-template kendoGridCellTemplate let-rowData>
                <div *ngIf="rowData.listType == 'QUERY'">
                  <a (click)="getViewWbaList(rowData)">Row Count</a>
                </div>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
      <p-dialog [header]="unAthorizesd != '' ? _confirmBox.header2 : _confirmBox.header" [modal]="true"
        [(visible)]="_confirmBox.isVisible" [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}"
        [draggable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p *ngIf="unAthorizesd == ''">{{_confirmBox.message}}</p>
        <p *ngIf="unAthorizesd != ''">{{unAthorizesd}}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button *ngIf="unAthorizesd == ''" (click)="_confirmBox.isVisible = false" label="No"
            styleClass="btn btn-outline-primary">
          </p-button>
          <p-button *ngIf="unAthorizesd != ''" (click)="_confirmBox.isVisible = false" label="OK"
            styleClass="btn btn-primary">
          </p-button>
          <p-button *ngIf="unAthorizesd == ''" (click)="deleteList()" label="Yes" styleClass="btn btn-primary">
          </p-button>
        </ng-template>
      </p-dialog>
      <p-dialog header="Alert" [modal]="true" [(visible)]="_confirmBox1.isVisible" [breakpoints]="{'960px': '75vw'}"
        [style]="{width: '40vw'}" [draggable]="false" [styleClass]="_confirmBox1.borderColor" (onHide)=" confirmBoxOk()"
        *ngIf="_confirmBox1">
        <p>{{_confirmBox1.message}}</p>

        <ng-template pTemplate="footer" *ngIf="_confirmBox1.hasFooter">
          <p-button (click)=" confirmBoxOk()" label="Ok" styleClass="btn btn-outline-primary">
          </p-button>
        </ng-template>
      </p-dialog>


      <!-- Alert message after email send -->
      <p-dialog [header]="_confirmBox1.header2" [modal]="true" [(visible)]="_confirmBox1.isVisible"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false"
        [styleClass]="_confirmBox1.borderColor" *ngIf="_confirmBox1">
        <p>{{_confirmBox1.message}}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox1.hasFooter">
          <p-button (click)="_confirmBox1.isVisible = false" label="OK" styleClass="btn btn-primary">
          </p-button>
        </ng-template>
      </p-dialog>


    </div>
  </div>
