import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialsComponent } from 'src/app/core/components/dials/dials.component';
import { AddContactComponent } from 'src/app/features/contact/components/add-contact/add-contact.component';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { PaginatorModel } from 'src/app/shared/class/paginatorModel';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { SelectedHeaderService } from 'src/app/shared/services/selected-header.service';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor, SortDescriptor, } from '@progress/kendo-data-query';
import { FilterExpression } from '@progress/kendo-angular-filter';
import {
  DataBindingDirective,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ContactService } from 'src/app/features/contact/services/contact.service';
import { CreateListScreen } from '../../../create-list-screen';
import { CreateListService } from '../../../services/CreateList.service';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';
import { SignalRNotifierService } from 'src/app/features/admin/services/Notifier Services/signalR-notifier.service';


@Component({
  selector: 'app-create-list-whiteboard',
  templateUrl: './create-list-whiteboard.component.html',
  styleUrls: ['./create-list-whiteboard.component.css']
})
export class CreateListWhiteboardComponent implements OnInit {

  @ViewChild('templateForState', { static: true })
  public templateForState !: TemplateRef<any>;
  @ViewChild('template2', { static: true })
  public template2 !: TemplateRef<any>;
  @ViewChild('templateForContactState', { static: true })
  public templateForContactState !: TemplateRef<any>;
  @ViewChild('filterDiv') filterDiv!: ElementRef<HTMLInputElement>;
  @ViewChild('templateForPolicy', { static: true })
  public templateForPolicy !: TemplateRef<any>;
  @ViewChild('templateForNumber', { static: true })
  public templateForNumber!: TemplateRef<any>;
  @ViewChild('templateForNumberEXP', { static: true })
  public templateForNumberEXP!: TemplateRef<any>;
  @ViewChild('templateForNumberWithoutComma', { static: true })
  public templateForNumberWithoutComma!: TemplateRef<any>;
  @ViewChild('templateForClientStatus', { static: true })
  public templateForClientStatus!: TemplateRef<any>;
  @ViewChild('templateForRecallStatus', { static: true })
  public templateForRecallStatus!: TemplateRef<any>;
  @ViewChild('templateForWbaStatus', { static: true })
  public templateForWbaStatus!: TemplateRef<any>;
  @ViewChild('templateForWbaBussinessStatus', { static: true })
  public templateForWbaBussinessStatus!: TemplateRef<any>;
  @ViewChild('templateForPrimaryIndustry', { static: true })
  public templateForPrimaryIndustry!: TemplateRef<any>;
  @ViewChild('templateForSecondaryIndustry', { static: true })
  public templateForSecondaryIndustry!: TemplateRef<any>;
  @ViewChild('templateForProgress', { static: true })
  public templateForProgress !: TemplateRef<any>;
  @ViewChild('templateForResult', { static: true })
  public templateForResult !: TemplateRef<any>;
  @ViewChild('templateprofitCenter', { static: true })
  public templateprofitCenter !: TemplateRef<any>;
  @ViewChild('templateForUserName', { static: true })
  public templateForUserName !: TemplateRef<any>;
  confirmationMsg: any;
  public whiteBoardActivityList: any = [];
  public finalWBAList: any = [];
  public FormattedFilterValues: any;
  masterWBAList: any = [];
  isDesc: boolean = false;
  column: any;
  errorList: any = [];
  _confirmBox: ConfirmBox;
  _errorBox: ErrorBox;
  _userMessages: UserMessages;
  id: any;
  searchText: string = '';
  rowCount = 20;
  _navigationPath: NavigationPath;
  isPrimary: boolean = false;
  sortIcon = 'sort-arw-up';
  _paginator: PaginatorModel;
  totelRecord: any;
  pageNo: number = 1;
  searchGrid: string = '';
  //finalExpValue:any;
  public multiple = false;
  pageTitle: string = 'Create List';
  invalidFilter: boolean = false;
  showError: boolean = false;
  errorMessage: any;
  //disableExpirationCalendar: boolean = false;
  dateAfterTwoMnths: Date = new Date();
  ListId: any;
  saveType: any = '';
  showSaveButton: boolean = false;
  minimumDate: Date;
  filteredDataProspectsIds: any[] = [];
  ListDetailData: any = {};
  isDataAvailable: boolean = false;
  selectedStateFilterValue: any;
  selectedLobFilterValue: any;
  filterValueArrayState: any[] = [];
  filterValueArrayLob: any[] = [];
  filterValueForState: any;
  filterValueForLob: any;
  gridFilterArrayLob: any[] = [];
  gridFilterArrayBussiness: any[] = [];
  gridFilterAccord: any[] = [];
  gridFilterProgress: any[] = [];
  gridFilterResult: any[] = [];
  policyTypeList: any[] = [];
  businessLine: any[] = [];
  gridOwnerWbaListArray: any[] = [];

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  public pageSize = 10;
  public buttonCount = 5;
  public sizes = [10, 25, 50, 100, 250, 500];
  public skip = 0;
  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  public sort: SortDescriptor[] = [
    {
      field: 'companyName',
      dir: "asc",
    }
  ];

  public defaultFilter: CompositeFilterDescriptor = { filters: [], logic: 'and' };
  public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public FormattedgridFilter: CompositeFilterDescriptor = { ...this.defaultFilter };
  public gridData: unknown[] = this.masterWBAList;
  selectedListId: number = 0;
  selectedParPageOption: number = 0;
  filterValueCopy: CompositeFilterDescriptor = { logic: 'and', filters: [] };

  //public filters: FilterExpression[] = [];
  public filters: FilterExpression[] = [
    {
      "field": "CompanyName",
      "title": "Company Name",
      "editor": "string"
    }
  ]

  isFilter: boolean = true;
  isFilter1: boolean = false;
  totalCount: number = 0;
  $searchingText = new Subject<void>();
  createWhiteboardForm: UntypedFormGroup;
  _createListScreen: CreateListScreen;
  isSubmitted: boolean = false;
  hideShowGrid: boolean = false;
  templateList: any;
  templateId: any;
  filterMsg: any;
  showListChangeWarning: boolean = false;
  blankArray: any[] = [];
  public dateFilters: any[] = [];
  blank: boolean = false;
  actionPerform = "ApplyFilter"
  whitespace: boolean = false;
  statesList: any;
  filterForLob: any;
  filterValueArraycontactState: any[] = [];
  selectedContactStateFilterValue: any;
  filterValueForContact: any;
  controlId: any;
  speciaPolicyType: any;
  resultList1: any;
  filterForAcordStatus: any;
  progress: any;
  clientStatus: any;
  recallForStatusList: any;
  wbaForStatusList: any;
  businessStatusList: any
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  gridFilterArrayPolicyType: any[] = [];
  prospectIndustryList: any;
  resultListForGrid: any;
  EpicStatusFilterForResult: any;
  filterBlock: boolean = true;
  profitCenterData: any;
  nextButtonVisiable: boolean = false;
  listType: string = "Dynamic";
  userList: any

  constructor(
    private _router: Router,
    private modalService: NgbModal,
    private _preventBack: PreventBackButtonService,
    private _selectedHeaderService: SelectedHeaderService,
    private fb: UntypedFormBuilder,
    private _customNotifireService: CustomNotifireService,
    public ValidateKey: KeyboardValidation,
    public datepipe: DatePipe,
    private _saveJsonService: CreateListService,
    private _ProspectListService: ProspectListService,
    private renderer: Renderer2,
    private _whiteBoardActivityService: WhiteBoardActivityService,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _AddprospectService: AddprospectService,
    private _addWBAService: WhiteBoardActivityService,
    private signalRNotifire: SignalRNotifierService,
    private ref: ChangeDetectorRef
  ) {
    this.dateAfterTwoMnths.setDate((new Date).getDate() + 60);
    this.minimumDate = new Date();
    this.minimumDate.setDate(this.minimumDate.getDate() + 1);
    this._navigationPath = new NavigationPath();
    sessionStorage.removeItem('contactId');
    sessionStorage.removeItem('prospectId');
    this._confirmBox = new ConfirmBox();
    this._errorBox = new ErrorBox();
    this._userMessages = new UserMessages();
    this._paginator = new PaginatorModel();
    this._createListScreen = new CreateListScreen();
    this.$searchingText.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.loadData(this.selectedListId);
    });
    this.createWhiteboardForm = this.fb.group({
      listName: ['', [Validators.required, Validators.maxLength(100)]],
      listType: ['Dynamic', [Validators.required]],
      template: [''],
      //expirationDate: ['']

    })
  }

  ngOnInit(): void {
    this.controlId = this.newGuid();
    this.getAllMasters();
    this.getUserList();
    this.filterForAcordStatus = ['Requested', 'Not Needed', 'Incomplete Information', 'Incomplete Contact', 'Completed', 'In Process'];
    this.resultList1 = ['Bound', 'Not Quoted,Not Bound', 'Not Renewed', 'Quoted,Not Bound', 'Null']
    //this.loadData();
    //checking form is opened in edit mode
    this.showListChangeWarning = this._router.url.includes('edit-list-whiteboard')
    if (this.showListChangeWarning) {
      sessionStorage.setItem('editMode', 'edit');
    }
    this.ListId = sessionStorage.getItem('manageListId');
    // this.getProspectStates();
    // this.getTemplates();
    //this.changeDetector();
    this._preventBack.preventBackButton(); //method to prevent browser back button
    this._customNotifireService.setActiveComponent('Whiteboard');

    this.createWhiteboardForm.get('template')?.disable();
  }

  getAllMasters() {
    let getTemplates = this._saveJsonService.getWbaTemplates()
    let whiteBoardListFilter = this._whiteBoardActivityService.getFilterJSON()
    let statesList = this._AddprospectService.getProspectStates();
    let getprogressList = this._whiteBoardActivityService.getprogressList()
    let businessStatus = this._addWBAService.getBusinessStatusList();
    let ProspectIndustry = this._AddprospectService.getProspectIndustry();
    let businessLine = this._addWBAService.getBusinessLineList();
    let policyType = this._addWBAService.getPolicyTypeList(5);

    forkJoin([getTemplates, whiteBoardListFilter, statesList, getprogressList, businessStatus, ProspectIndustry, businessLine, policyType]).subscribe(resp => {
      if (resp) {
        this.templateList = resp[0].list;
        this.filters = resp[1].masterFilterOptionList;
        this.statesList = resp[2].statesList;
        this.progress = resp[3].progressList;
        this.businessStatusList = resp[4].businessStatusList;
        this.prospectIndustryList = resp[5].prospectIndustryList;
        this.businessLine = resp[6].businessLineList;
        this.policyTypeList = resp[7].businessLineList;
        if (this.ListId > 0) {
          this.pageTitle = 'Edit List'
          this.getQueryById(this.ListId)
        }
        this.getFilterJSON();
      }
    },
      (error) => {
        let obj = error.error.errors;
        if (obj) {
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
          this.errorList = arr;
        }
      });
  }

  public getFilterJSON(): void {
    // this._whiteBoardActivityService.getFilterJSON().subscribe((res: any) => {
    //   this.filters = res.masterFilterOptionList;

    // let filterFoeState = res.masterFilterOptionList[26].filterOptions;
    // let filterforContactState = res.masterFilterOptionList[7].filterOptions
    this.clientStatus = ['Not a client', 'Client', 'Lost Client'];
    this.recallForStatusList = ['Open', 'Completed', 'Expired'];
    this.wbaForStatusList = ['Open', 'Won', 'Lost'];
    this.resultListForGrid = ['Bound', 'Not Quoted,Not Bound', 'Not Renewed', 'Quoted,Not Bound', 'Open'];
    this.profitCenterData = ['Lamb', 'CREIS', 'TruePartners', 'TruePartners - Wheels'];
    let stateArray: any[] = []
    this.filters.forEach((element: any) => {
      if (element?.field == 'State') {
        element.operators = ["eq"];

      }
      else if (
        element.field == 'ProspectInformation.NoOfEmployees' ||
        element.field == 'ProspectInformation.Revenue' ||
        element.field == 'ProspectInformation.PackagePremium' ||
        element.field == 'ProspectInformation.WorkersCompensationPremium' ||
        element.field == 'TargetPremium' ||
        element.field == 'WBAOutComeDetail.BoundRevenue' ||
        element.field == 'Prospect.TotalRevenue' ||
        element.field == 'Prospect.DispositionCount'
      ) {
        element.editorTemplate = this.templateForNumber;
      }
      else if (
        element.field == 'ProspectInformation.EXPMOD'
      ) {
        element.editorTemplate = this.templateForNumberEXP;
      }
      else if (
        element.field == 'WBA.WBAId' || element.field == 'WBA.ProspectId'
      ) {
        element.editorTemplate = this.templateForNumberWithoutComma;
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'MasterBusinessLine') {
        element.operators = ["eq"];

      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'Office1State') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'MasterPolicyType.line') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'prospectstatus.Status') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'WBARecall.Status') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'WBAStatusId') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'MasterBusinessStatus') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'ProspectIndustry.IndustryName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'PI.IndustryName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'Prospect.CreatedBy') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'WBA.CreatedBy') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'WBA.OwnerUserName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'WBA.MarketingManagerId') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'wba.OriginalProducerName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'Prospect.LastContactedBy') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'Prospect.ModifiedBy') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'EpicStatusName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'ProfitCenter') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'ProgressName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'pmv_view.TagName' || element.field == 'Tag.Name') {
        element.operators = ["contains", "eq", "neq", "doesnotcontain", "isnull"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element.field == 'industryclassandcodeinformation.Class.NTEE') {
        element.operators = ["contains", "eq", "neq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element.field == 'industryclassandcodeinformation.Class.PCS') {
        element.operators = ["contains", "eq", "neq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element.field == 'industryclassandcodeinformation.Class.SIC') {
        element.operators = ["contains", "eq", "neq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element.field == 'industryclassandcodeinformation.Class.NAICS') {
        element.operators = ["contains", "eq", "neq"];
      }
    });
    // let stateForContactArray: any[] = []
    // filterforContactState.forEach((element: any) => {
    //   stateForContactArray.push(element.stateCode)
    // });
    let getStateIndex = this.filters.findIndex((x: any) => x.field == 'State')!
    let getLobIndex = this.filters.findIndex((x: any) => x.field == 'MasterBusinessLine')!
    this.filters[getStateIndex].editorTemplate = this.templateForState;
    this.filters[getLobIndex].editorTemplate = this.template2;
    let getContactStateIndex = this.filters.findIndex((x: any) => x.field == 'Office1State')!
    this.filters[getContactStateIndex].editorTemplate = this.templateForContactState;
    let getProspectCreatedBy = this.filters.findIndex((x: any) => x.field == "Prospect.CreatedBy")!
    this.filters[getProspectCreatedBy].editorTemplate = this.templateForUserName;
    let getWBACreatedBy = this.filters.findIndex((x: any) => x.field == "WBA.CreatedBy")!
    this.filters[getWBACreatedBy].editorTemplate = this.templateForUserName;
    let getownerUserName = this.filters.findIndex((x: any) => x.field == "WBA.OwnerUserName")!
    this.filters[getownerUserName].editorTemplate = this.templateForUserName;
    let getWBAMarketingManagerId = this.filters.findIndex((x: any) => x.field == "WBA.MarketingManagerId")!
    this.filters[getWBAMarketingManagerId].editorTemplate = this.templateForUserName;
    let getwbaOriginalProducerName = this.filters.findIndex((x: any) => x.field == "wba.OriginalProducerName")!
    this.filters[getwbaOriginalProducerName].editorTemplate = this.templateForUserName;
    let getProspectLastContactedBy = this.filters.findIndex((x: any) => x.field == "Prospect.LastContactedBy")!
    this.filters[getProspectLastContactedBy].editorTemplate = this.templateForUserName;
    let getProspectModifiedBy = this.filters.findIndex((x: any) => x.field == "Prospect.ModifiedBy")!
    this.filters[getProspectModifiedBy].editorTemplate = this.templateForUserName;
    let getspecialPolicyIndex = this.filters.findIndex((x: any) => x.field == 'MasterPolicyType.line')!
    this.filters[getspecialPolicyIndex].editorTemplate = this.templateForPolicy;
    this.dateFilters = this.filters.filter((obj: any) => obj.editor === 'date');
    let getspecialClientStatus = this.filters.findIndex((x: any) => x.field == 'prospectstatus.Status')!
    this.filters[getspecialClientStatus].editorTemplate = this.templateForClientStatus;
    let getRecallStatus = this.filters.findIndex((x: any) => x.field == 'WBARecall.Status')!
    this.filters[getRecallStatus].editorTemplate = this.templateForRecallStatus;
    let getWbaStatus = this.filters.findIndex((x: any) => x.field == 'WBAStatusId')!
    this.filters[getWbaStatus].editorTemplate = this.templateForWbaStatus;
    let getWbaBussinessStatus = this.filters.findIndex((x: any) => x.field == 'MasterBusinessStatus')!
    this.filters[getWbaBussinessStatus].editorTemplate = this.templateForWbaBussinessStatus;
    let getPrimaryIndustry = this.filters.findIndex((x: any) => x.field == 'ProspectIndustry.IndustryName')!
    this.filters[getPrimaryIndustry].editorTemplate = this.templateForPrimaryIndustry;
    let getSecondaryIndustry = this.filters.findIndex((x: any) => x.field == 'PI.IndustryName')!
    this.filters[getSecondaryIndustry].editorTemplate = this.templateForSecondaryIndustry;
    let getspecialProgressIndex = this.filters.findIndex((x: any) => x.field == 'ProgressName')!
    this.filters[getspecialProgressIndex].editorTemplate = this.templateForProgress;
    let getspecialResultIndex = this.filters.findIndex((x: any) => x.field == 'EpicStatusName')!
    this.filters[getspecialResultIndex].editorTemplate = this.templateForResult;
    let getprofitCenterIndex = this.filters.findIndex((x: any) => x.field == 'ProfitCenter')!
    this.filters[getprofitCenterIndex].editorTemplate = this.templateprofitCenter;


    // })
  }

  getProspectStates(): void {
    this._AddprospectService.getProspectStates().subscribe((response: any) => {
      this.statesList = response.statesList
    });
  }
  getProspectIndustry(): void {
    this._AddprospectService.getProspectIndustry().subscribe((response: any) => {
      this.prospectIndustryList = response.prospectIndustryList;
    });
  }

  //to get policy Type list for speciality
  getPolicyTypeList() {
    this._addWBAService.getPolicyTypeList(5).subscribe(resp => {
      this.policyTypeList = resp.businessLineList
    })
  }

  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }
  onlistTypeChange(type: string) {
    this.listType = type;
    this.nextButtonVisiable = false;
    this.isDataAvailable = false;
    this.hideShowGrid = false;
    if (type == "Dynamic") {
      this.showSaveButton = false;
    } else if (type == "System-Dafault") {
      this.showSaveButton = true;
    }
    else {
      //template
      //this.disableExpirationCalendar = true;
      this.showSaveButton = true;
    }
  }
  onTemplateChange(event: any) {
    this.templateId = event.target.value.split(': ').pop();;
    if (this.templateId == "") {
      //  this.templateId = 0;
      this.createWhiteboardForm.controls['listType'].patchValue('Dynamic');
      this.createWhiteboardForm.controls['listName'].patchValue('');
      this.filterValue = { logic: 'and', filters: [] };
      this.filterValueCopy = { logic: 'and', filters: [] };
      this.filterValue.filters.length > 0 ? JSON.stringify(this.filterValue) : ""
      this.filterValueCopy.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : ""
      this.loadData(0);
    }

    if (this.templateId > 0) {
      this.getTemplateJSON(this.templateId)
    }
  }

  onTemplateCheck(event: any) {
    if (event.target.checked == true) {
      this.createWhiteboardForm.get('template')?.enable();
    } else {
      this.filterValue = { logic: 'and', filters: [] };
      this.filterValue.filters.length > 0 ? JSON.stringify(this.filterValue) : ""
      if (this.templateId > 0) {
        this.templateId = 0;
        this.loadData(0);
      }
      this.createWhiteboardForm.get('template')?.disable();
      this.createWhiteboardForm.get('template')?.setValue('');
    }
  }
  //get WBA list details By id
  getQueryById(id: any) {
    this._ProspectListService.getWBAById(id).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listFilter;
          this.setFormData();
        }
      }),
      error: (() => {

      })
    })
  }

  checkBlankSpace(event: any) {
    if (event.target.value.trim().length == 0) {
      this.whitespace = false;
    }
  }

  //get Template details By id
  getTemplateJSON(id: any) {
    this._ProspectListService.getWBAById(id).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listFilter;
          // if (JSON.parse(this.ListDetailData.filterJson))
          this.filterValue = JSON.parse(this.ListDetailData.filterJson);
          this.filterValueCopy = JSON.parse(this.ListDetailData.filterJson);
          this.convertContainsIntoEquals(this.filterValue);
          this.loadData(id)

        }
      }),
      error: (() => {

      })
    })
  }
  getTemplates() {

    this._saveJsonService.getWbaTemplates().subscribe((resp: any) => {
      this.templateList = resp.list;
    })
  }


  DateFormattingInAllGroups(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.DateFormattingInAllGroups(x)
        }
        else {
          let fieldType = this.filters.find((f: any) => f.field.toLowerCase() === x.field.toLowerCase())?.editor;
          if (fieldType) {
            if (fieldType?.toLowerCase() == 'date') {
              // var myDate = new Date(x.value).toISOString();
              x.value = this.datepipe.transform(x.value, 'yyyy-MM-dd');
            }
          }
        }
      })
    }
  }

  changeDetector() {
    this.ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 2000);
  }


  // Telrik functions start
  applyFilter(value: CompositeFilterDescriptor, val: boolean): void {
    this.filterBlock = false;
    this.ref.detectChanges();
    this.filterValueCopy = JSON.parse(JSON.stringify(value));
    this.filterValue = value;
    this.pageNo = 1;
    this.totalCount = 0;
    this.skip = 0;
    this.DateFormattingInAllGroups(this.filterValueCopy);
    this.isFilter1 = !val;
    this.actionPerform = 'ApplyFilter';
    this.loadData(this.selectedListId);

  }

  resetOptions(): void {
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
  }

  //contact State select dropdown
  public onChangeForContactState(event: any) {
    this.selectedContactStateFilterValue = event.toString()
  }

  getUserList() {
    let data = {
      "lookUpType": 'AllUser',
      "rowCount": 500
    }
    this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((resp: any) => {
      this.userList = resp.userList;
    })
  }


  public wbaOwnerListChange(event: any): void {
    this.actionPerform = "FilterChange"
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "ownerUserName")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "ownerUserName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "ownerUserName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  // changing the format for the filters of the date type
  public getFormattedJSON(data: any) {
    data.map((obj: any) => {
      if (obj.field) {
        let index = this.dateFilters.findIndex((obj1: any) => obj1.field.toLocaleLowerCase() === obj.field.toLocaleLowerCase());

        if (index > -1) {
          let record: any = this.datepipe.transform(obj.value, 'yyyy-MM-dd');
          obj.value = record;
        }
      } else if (obj.filters) {
        obj.filters = this.getFormattedJSON(obj.filters)
      }
      // let isExist = this.gridFilter.filters.some((x: any) => x.field.toLowerCase() == obj.field.toLowerCase());
      // if (!isExist) {
      //   this.gridFilter.filters.push(obj);
      // }
    });
    return data;
  }

  //searching on all columns
  onSearch(): void {
    if (this.searchText === "") {
      return
    }
    else {
      this.resetOptions();
      this.loadData(this.selectedListId);
    }
  }

  searchValue(inputValue: any) {
    this.searchText = inputValue;
  }

  //multiselect ngmodel
  getMultiselectValueChange(currentItem: any) {
    return currentItem;
  }

  gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.actionPerform = "FilterChange"
    this.gridFilter = { ...filter };
    // this.FormattedgridFilter.filters = this.getFormattedJSON(filter.filters);

    if (this.gridFilter.filters.length > 0) {
      var BussinessLineIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "masterBusinessLine")!
      if (BussinessLineIndex > -1 && this.gridFilterArrayLob.length == 0)
        this.gridFilter.filters.splice(BussinessLineIndex, 1)
      if (BussinessLineIndex < 0) this.gridFilterArrayLob = []

      var OwnerWbaListIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "ownerUserName")!
      if (OwnerWbaListIndex > -1 && this.gridOwnerWbaListArray.length == 0)
        this.gridFilter.filters.splice(OwnerWbaListIndex, 1)
      if (OwnerWbaListIndex < 0) this.gridOwnerWbaListArray = [];

      var bussinessStatusIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "masterBusinessStatus")!
      if (bussinessStatusIndex > -1 && this.gridFilterArrayBussiness.length == 0)
        this.gridFilter.filters.splice(bussinessStatusIndex, 1)
      if (bussinessStatusIndex < 0) this.gridFilterArrayBussiness = []

      var progressNameIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "progressName")!
      if (progressNameIndex > -1 && this.gridFilterProgress.length == 0)
        this.gridFilter.filters.splice(progressNameIndex, 1)
      if (progressNameIndex < 0) this.gridFilterProgress = []

      var resultIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "epicStatusName")!
      if (resultIndex > -1 && this.gridFilterResult.length == 0)
        this.gridFilter.filters.splice(resultIndex, 1)
      if (resultIndex < 0) this.gridFilterResult = []

      var policyTypeIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "policyType")!
      if (policyTypeIndex > -1 && this.gridFilterArrayPolicyType.length == 0)
        this.gridFilter.filters.splice(policyTypeIndex, 1)
      if (policyTypeIndex < 0) this.gridFilterArrayPolicyType = []


      var accordIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "acordStatus")!
      if (accordIndex > -1 && this.gridFilterAccord.length == 0)
        this.gridFilter.filters.splice(accordIndex, 1)
      if (accordIndex < 0) this.gridFilterAccord = []
    }

    if (!this.gridFilter?.filters.length) {
      this.gridFilterArrayLob = [];
      this.gridFilterArrayBussiness = [];
      this.gridFilterProgress = [];
      this.gridFilterResult = [];
      this.gridFilterArrayPolicyType = [];
      this.gridFilterAccord = [];
      this.gridOwnerWbaListArray = [];
    }

    this.resetOptions();
    if (this.gridFilterResult != undefined && this.gridFilterResult.length > 0)
      this.onResultListChange(this.gridFilterResult);

    if (this.gridOwnerWbaListArray != undefined && this.gridOwnerWbaListArray.length > 0)
      this.wbaOwnerListChange(this.gridOwnerWbaListArray);

    if (this.gridFilterProgress != undefined && this.gridFilterProgress.length > 0)
      this.onProgressListChange(this.gridFilterProgress);

    if (this.gridFilterAccord != undefined && this.gridFilterAccord.length > 0)
      this.onAccordStatusChange(this.gridFilterAccord);

    if (this.gridFilterArrayBussiness != undefined && this.gridFilterArrayBussiness.length > 0)
      this.onBussinessStatusChange(this.gridFilterArrayBussiness);

    if (this.gridFilterArrayLob != undefined && this.gridFilterArrayLob.length > 0)
      this.onLineOfBusinessChange(this.gridFilterArrayLob);

    if (this.gridFilterArrayPolicyType != undefined && this.gridFilterArrayPolicyType.length > 0)
      this.onPolicyTypeChange(this.gridFilterArrayPolicyType);
    this.$searchingText.next();
  }


  checkData(value: CompositeFilterDescriptor) {
    return JSON.stringify(value) === JSON.stringify(this.defaultFilter) ? "" : JSON.stringify(value);
  }



  loadData(listId: number, signalR = false) {
    let request;
    let __tempFilter: any = this.convertArrayToString(this.filterValueCopy);
    let _tempFilter: any = this.convertEqualsIntoContains(__tempFilter);
    this.filterValueCopy = this.convertStringToBool(this.filterValueCopy);
    let tempgridFilter = JSON.parse(JSON.stringify(this.gridFilter));
    tempgridFilter = this.convertDateStringInFormatting(tempgridFilter)

    if (listId == 0) {
      request = {
        listId: 0,
        sortDir: this.sort[0].dir,
        sortCol: this.sort[0].field == 'wbaCreatedDate' ? this.sort[0].field = 'wbacreateddate' : this.sort[0].field,
        //filterJson: this.checkData(this.FormattedFilterValues),
        //filterJson: this.filterValue.filters.length > 0 ? JSON.stringify(this.filterValue) : "",
        searchText: this.searchText,
        pageNumber: this.pageNo,
        pageSize: this.pageSize,
        filterJson: _tempFilter.filters != null && _tempFilter.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : '',
        gridFilterJson: tempgridFilter.filters != null && tempgridFilter.filters.length > 0 ? JSON.stringify(tempgridFilter) : '',
        //gridFilterJson: this.gridFilter.filters.length > 0 ? JSON.stringify(this.gridFilter) : "",
        totalCount: signalR ? -1 : this.totalCount,
        UserId: this.controlId
      }
    } else {
      request = {
        listId: listId,
        sortDir: this.sort[0].dir,
        sortCol: this.sort[0].field == 'wbaCreatedDate' ? this.sort[0].field = 'wbacreateddate' : this.sort[0].field,
        filterJson: _tempFilter.filters != null && _tempFilter.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : '',
        gridFilterJson: tempgridFilter.filters != null && tempgridFilter.filters.length > 0 ? JSON.stringify(tempgridFilter) : '',
        searchText: this.searchText,
        pageNumber: this.pageNo,
        pageSize: this.pageSize,
        totalCount: signalR ? -1 : this.totalCount,
        UserId: this.controlId
      }
    }
    if (listId.toString() == '0' && request.totalCount == 0) this.loadData(listId, true)
    this._whiteBoardActivityService.wbaPagination(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp: any) => {
      this.filterBlock = true;
      if (resp && resp.items.length > 0) {
        this.totelRecord = resp.totalRecord;
        if (!signalR) {
          this.whiteBoardActivityList = resp.items[0].whiteBoardActivityList;
          this.masterWBAList = this.whiteBoardActivityList;
        }

        if (signalR) {
          this.finalWBAList.total = resp.totalRecord;
          if (signalR && resp.totalRecord == 0) { this.finalWBAList.data = []; this.ngUnsubscribe.next(); }
        }
        else {
          this.finalWBAList.data = this.masterWBAList;
          this.finalWBAList.total = resp.totalRecord > 0 ? resp.totalRecord : this.finalWBAList.total;
        }
        // this.convertingToDateType();
        if (this.actionPerform == 'ApplyFilter' && this.masterWBAList.length > 0) {
          this.hideShowGrid = false
          this.isDataAvailable = true;
          return;
        }
        if (this.actionPerform == 'ApplyFilter' && this.masterWBAList.length == 0) {
          this.hideShowGrid = true;
          this.searchGrid = "";
          this.isDataAvailable = false;
          this.nextButtonVisiable = false;
          this.filterMsg = "No Data Available";
          if (this.listType == "Dynamic") {
            this.nextButtonVisiable = true;
            this.isDataAvailable = false;
            this.hideShowGrid = true;
          }
          return;
        }
        if (this.actionPerform == 'FilterChange') {
          this.hideShowGrid = false
        }
        if (this.masterWBAList.length > 0 && this.actionPerform == 'searchAll') {
          this.isDataAvailable = true;
          this.renderer.removeClass(this.filterDiv.nativeElement, 'show');
          return;
        }
        if (this.masterWBAList.length == 0 && this.actionPerform == 'searchAll') {
          this.renderer.addClass(this.filterDiv.nativeElement, 'show');
          return;
        }

      }
    });
  }

  setFormData() {
    this.invalidFilter = false
    let listType = 'Dynamic'
    //condition applied because test cases was giving error
    if (this.ListDetailData) {
      // if (this.ListDetailData.listType == 'QUERY' && this.ListDetailData.saveAs == 'Draft') {
      //   listType = 'Dynamic'
      // }
      if (this.ListDetailData.listType == 'QUERY' && this.ListDetailData.saveAs == 'Template') {
        listType = 'Template'
        this.showSaveButton = true;
      }


      this.createWhiteboardForm.controls['listType'].patchValue(listType);
      this.createWhiteboardForm.controls['listName'].patchValue(this.ListDetailData.listName);
      //this.createContactForm.controls['template'].patchValue(this.newMasterFilterList[1].template.id);
      // if (this.ListDetailData.expirationDate != null) {
      //   this.createWhiteboardForm.controls['expirationDate'].patchValue(new Date(this.ListDetailData.expirationDate));
      // }
      // this.createContactForm.controls['saveAs'].patchValue(this.ListDetailData.saveAs);
      if (this.ListDetailData.filterJson) {
        this.filterValue = JSON.parse(this.ListDetailData.filterJson);
        this.filterValueCopy = JSON.parse(this.ListDetailData.filterJson);
        this.convertContainsIntoEquals(this.filterValue);
        this.convertStringIntoDate(this.filterValue);
        this.selectedStateFilterValue = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "State")[0]?.value;
        this.selectedLobFilterValue = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "MasterBusinessLine")[0]?.value;
        this.filterValueForState = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "State")[0]?.value;
        this.filterValueArrayState = this.filterValueForState?.split(',');
        this.filterValueForLob = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "MasterBusinessLine")[0]?.value
        this.filterValueArrayLob = this.filterValueForLob?.split(',');
        this.filterValueForContact = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "Office1State")[0]?.value
        this.filterValueArraycontactState = this.filterValueForContact?.split(',');

        this.EpicStatusFilterForResult = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "EpicStatusName")[0]?.value;
        if (this.EpicStatusFilterForResult == "Null") {
          this.EpicStatusFilterForResult = "Open"
        }
        if (this.EpicStatusFilterForResult) {
          let commaValue1 = false, commaValue2 = false;
          if (this.EpicStatusFilterForResult.includes('Not Quoted,Not Bound')) {
            this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/Not Quoted,Not Bound/g, "");
            if (this.EpicStatusFilterForResult.includes(',,')) {
              this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/,,/g, ",");
            }
            commaValue1 = true;
          }
          if (this.EpicStatusFilterForResult.includes('Quoted,Not Bound')) {
            this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/Quoted,Not Bound/g, "");
            if (this.EpicStatusFilterForResult.includes(',,')) {
              this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/,,/g, ",");
            }
            commaValue2 = true;
          }
          this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/(\s*,?\s*)*$/, "");

          this.filterValue.filters.forEach((x: any) => {
            if (x.field == "EpicStatusName") {
              x.value = this.EpicStatusFilterForResult?.split(',');
              if (commaValue1) {
                x.value.push("Not Quoted,Not Bound")
              }
              if (commaValue2) {
                x.value.push("Quoted,Not Bound")
              }
            }
          })

        }


      }
    }
    //getting prosects by listId
    this.loadData(this.ListId)

  }

  convertingToDateType() {
    this.whiteBoardActivityList.map((obj: any) => {
      if (obj.effectiveDate) {
        obj.effectiveDate = new Date(obj.effectiveDate);
      }
    })
  }

  sendData(type: any) {
    this.isSubmitted = true
    this.saveType = type;
    if (this.filterValue.filters.length == 0) {
      this.invalidFilter = true
      return
    }
    if (!this.createWhiteboardForm.valid) {
      return
    }

    if (this.f.listName.value.trim().length === 0) {
      this.whitespace = true
      return
    }
    //for edit mode
    if (this.showListChangeWarning) {
      // this.cancel()
      this.saveDataApiCall();
    } else {
      //for create mode
      this.saveDataApiCall();

    }
  }

  saveDataApiCall() {
    let __tempFilter: any = this.convertArrayToString(this.filterValueCopy);
    let _tempFilter: any = this.convertEqualsIntoContains(__tempFilter);;
    this.filterValueCopy = this.convertStringToBool(this.filterValueCopy);
    this.isSubmitted = true
    if (this.filterValue.filters.length == 0) {
      this.invalidFilter = true
      return
    }
    if (!this.createWhiteboardForm.valid) {
      return
    }
    let listType = 'Query'
    if (this.f.listType.value == 'System-Default' || this.f.listType.value == 'Template') {
      listType = 'Query'
    }
    let saveAs = 'Draft'
    if (this.f.listType.value == 'System-Default') {
      saveAs = 'System-Default'
    }
    if (this.f.listType.value == 'Template') {
      saveAs = 'Template'
    }

    let data = {
      "listFilter": {
        listId: this.ListId,
        ListEntityType: "WBA",
        ListType: listType,
        SaveAs: saveAs,
        TemplateId: this.templateId,
        listName: this.f.listName.value,
        filterJson: JSON.stringify(this.filterValueCopy),
        totalCount: this.finalWBAList.total
      }
    };

    this.errorList = []

    this._saveJsonService.saveWhiteBoardList(data).subscribe((resp: any) => {
      if (this.errorList.length == 0) {
        this.showError = false;

        // this._listDataService.setListId(resp)
        sessionStorage.setItem('listId', resp);
        //again setting Query to Dynamic for list type display
        if (data.listFilter.ListType == 'Dynamic') {
          data.listFilter.ListType = 'Query'
        }
        // this._listDataService.setListDetailedData(data.listFilter)

        sessionStorage.setItem('manageListId', resp);

        this.createWhiteboardForm.controls['listName'].patchValue('');
        this.filterValue = { logic: 'and', filters: [] };
        this.createWhiteboardForm.controls.listName?.setValidators(null);
        this.createWhiteboardForm.controls.listName?.updateValueAndValidity();
        // if (this.f.listType.value == 'Static')
        //   this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'create-contact-column-list');
        if (this.f.listType.value == 'Dynamic')
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'create-whiteBoard-share-list');
        else {
          sessionStorage.removeItem('manageListId');
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
        }
        //  this.saveSuccessfullyPopup()
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorMessage = "Error : " + arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {

            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  }

  closeError() {
    this.showError = false;
  }

  // trackByContactId(item: any) {
  //   return item.id;
  // }

  navigate(id: any, prospectid: any, comp: any) {
    sessionStorage.setItem('contactId', id);
    // localStorage.setItem('prospectId', prospectid);
    let viewDetailsData = {
      prospectId: prospectid,
      listTitle: 'All Prospects'
    }
    sessionStorage.setItem('viewDetailsData', JSON.stringify(viewDetailsData));

    if (comp == this._navigationPath.getContactsViewUrl()) {
      this._router.navigateByUrl('/' + this._navigationPath.getContactsUrl() + '/' + comp);
    } else {
      this._customNotifireService.setActiveComponent('Prospect');
      this._selectedHeaderService.setSelectedHeader(
        this._navigationPath.getProspectsUrl()
      );
      this._router.navigateByUrl('/' + this._navigationPath.getProspectsUrl() + '/' + comp);
    }
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    // this.activeModal.close();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = this.finalWBAList.total
    this.sort = sort;
    this.loadData(this.selectedListId);
  }

  whiteBoardPaginate(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNo = (event.take + event.skip) / event.take;
    this.totalCount = this.finalWBAList.total
    this.loadData(this.selectedListId)
  }

  get f(): any {
    return this.createWhiteboardForm?.controls;
  }

  //To hide column filters
  columnFilter(value: boolean) {
    this.isFilter = !value;
  }

  public onChangeForLob(event: any) {
    //state is not in filter
    if (event.length == 0) {
      var lobIndex = this.filterValue.filters.findIndex((x: any) => x.field == "MasterBusinessLine")
      if (lobIndex > -1)
        this.filterValue.filters.splice(lobIndex, 1)
    }
    //state find in filter
    if (this.filterValue.filters.length > 0 || event !== null || event !== undefined) {
      var lobIndex = this.filterValue.filters.findIndex((x: any) => x.field == "MasterBusinessLine")
      if (lobIndex > -1)
        this.filterValue.filters.splice(lobIndex, 1)
      this.selectedLobFilterValue = event.toString()

    }
  }

  public onChangeForState(event: any) {
    //state is not in filter
    if (event.length == 0) {
      var stateIndex = this.filterValue.filters.findIndex((x: any) => x.field == "State")
      if (stateIndex > -1)
        this.filterValue.filters.splice(stateIndex, 1)
    }
    //state find in filter
    if (this.filterValue.filters.length > 0 || event !== null || event !== undefined) {
      var stateIndex = this.filterValue.filters.findIndex((x: any) => x.field == "State")
      if (stateIndex > -1)
        this.filterValue.filters.splice(stateIndex, 1)
      this.selectedStateFilterValue = event.toString()

    }

  }
  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });

  }

  //multiselect ngmodel
  getMultiselectValue(currentItem: any) {
    typeof currentItem.value == 'string' && currentItem.value != ""
      ? (currentItem.value = currentItem.value.split(','))
      : currentItem.value;
    return currentItem.value;
  }

  //multiselect for industry
  getMultiselectValueByid(currentItem: any) {
    typeof currentItem.value == 'string' && currentItem.value != ""
      ? (currentItem.value = currentItem.value.split(',').map(Number))
      : currentItem.value;
    return currentItem.value;
  }
  //numerical ngmodel
  getNumericValue(currentItem: any) {
    if (currentItem.value) {
      return +currentItem.value;
    } else {
      return currentItem.value;
    }
  }

  getNumericValueEXP(currentItem: any) {

    if (currentItem.value) {
      //let expValue = this.ValidateKey.formatDecimalNumber(currentItem.value.toString());
      let _curValue = currentItem.value.toString();
      let index0 = _curValue.charAt(0)
      let index1 = '.'
      let index2 = _curValue.charAt(_curValue.includes('.') ? 2 : 1)
      let index3 = _curValue.charAt(_curValue.includes('.') ? 3 : 2)
      let expValue = index0 + index1 + index2 + index3;
      return (+expValue);
    } else {
      return currentItem.value;
    }
  }

  //convert decimal to int
  public onChangeForNumber(
    event: any,
    currentItem: FilterDescriptor
  ) {
    currentItem.value = event.toFixed();
  }

  //multiselect select dropdown
  public onChangeForMultiselect(
    event: any,
    currentItem: FilterDescriptor,
  ) {
    currentItem.value = event;
  }

  //covert array to string
  convertArrayToString(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertArrayToString(x);
        } else {
          x.value = x?.value?.toString();
        }
      });
    }
    return json;
  }


  //to get Progress list
  getProgressList() {
    this._whiteBoardActivityService.getprogressList().subscribe(resp => {
      this.progress = resp.progressList;
    })
  }

  //to get Business Line list
  getBusinessLineList() {
    this._whiteBoardActivityService.getBusinessLineList().subscribe(resp => {
      this.businessLine = resp.businessLineList;
    })
  }
  getBusinessStatusList(): void {
    this._whiteBoardActivityService.getBusinessStatusList().subscribe((response: any) => {
      this.businessStatusList = response.businessStatusList
    });
  }

  public onAccordStatusChange(event: any): void {
    this.actionPerform = "FilterChange"
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    this.gridFilterAccord = event;
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "acordStatus")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "acordStatus",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "acordStatus",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  public onResultListChange(event: any): void {
    this.actionPerform = "FilterChange"
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    this.gridFilterResult = event;
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "epicStatusName")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "epicStatusName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "epicStatusName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  public onProgressListChange(event: any): void {
    this.actionPerform = "FilterChange"
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    this.gridFilterProgress = event;
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "progressName")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "progressName",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "progressName",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }


  //policy type
  public onPolicyTypeChange(event: any): void {
    this.actionPerform = "FilterChange"
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    this.gridFilterArrayPolicyType = event;
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "policyType")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "policyType",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "policyType",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }


  public onLineOfBusinessChange(event: any): void {
    this.actionPerform = "FilterChange"
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    this.gridFilterArrayLob = event;
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "masterBusinessLine")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "masterBusinessLine",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "masterBusinessLine",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }


  //for grid column bussiness status
  public onBussinessStatusChange(event: any): void {
    this.actionPerform = "FilterChange"
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    this.gridFilterArrayBussiness = event;
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "masterBusinessStatus")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "masterBusinessStatus",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "masterBusinessStatus",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.resetOptions();
    this.$searchingText.next();
  }

  //convert string to bool
  convertStringToBool(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertStringToBool(x);
        } else {
          x.value = (x.value === 'true') ? true : (x.value === 'false') ? false : x.value;
        }
      });
    }
    return json;
  }


  //convert eq operator to contains operator
  convertEqualsIntoContains(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertEqualsIntoContains(x)
        }
        else {
          if (x.operator == 'eq') {
            if (x.field === 'State' || x.field === 'MasterBusinessStatus' || x.field === 'WBAStatusId'
              || x.field === 'WBARecall.Status' || x.field === 'prospectstatus.Status' || x.field === 'MasterPolicyType.line'
              || x.field === 'Office1State' || x.field === 'MasterBusinessLine' || x.field === 'ProspectIndustry.IndustryName'
              || x.field === 'PI.IndustryName' || x.field === 'ProgressName' || x.field === 'EpicStatusName' || x.field === 'ProfitCenter' || x.field === 'ownerUserName' ||
              x.field === 'WBA.OwnerUserName' || x.field === "WBA.MarketingManagerId" || x.field === 'wba.OriginalProducerName' || x.field === 'Prospect.LastContactedBy' || x.field === 'Prospect.ModifiedBy' || x.field === 'Prospect.CreatedBy' || x.field === 'WBA.CreatedBy') {
              x.operator = 'contains';
            }
          } else if (x.operator == 'neq') {
            if (x.field === 'industryclassandcodeinformation.Class.NAICS' || x.field === 'industryclassandcodeinformation.Class.SIC'
              || x.field === 'industryclassandcodeinformation.Class.PCS' || x.field === 'industryclassandcodeinformation.Class.NTEE') {
              x.operator = 'doesnotcontain'
            }
          }
        }
      })
    }
    return json;
  }

  //convert contains operator to eq operator
  convertContainsIntoEquals(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertContainsIntoEquals(x)
        }
        else {
          if (x.operator == 'contains') {
            if (x.field === 'State' || x.field === 'MasterBusinessStatus' || x.field === 'WBAStatusId'
              || x.field === 'WBARecall.Status' || x.field === 'prospectstatus.Status' || x.field === 'MasterPolicyType.line'
              || x.field === 'Office1State' || x.field === 'MasterBusinessLine' || x.field === 'ProspectIndustry.IndustryName'
              || x.field === 'PI.IndustryName' || x.field === 'ProgressName' || x.field === 'EpicStatusName' || x.field === 'ProfitCenter' || x.field === 'ownerUserName' ||
              x.field === "WBA.OwnerUserName" || x.field === "WBA.MarketingManagerId" || x.field === 'wba.OriginalProducerName' || x.field == 'Prospect.LastContactedBy' || x.field == 'Prospect.ModifiedBy' || x.field === 'Prospect.CreatedBy' || x.field === 'WBA.CreatedBy') {
              x.operator = 'eq';
            }
          } else if (x.operator == 'doesnotcontain') {
            if (x.field === 'industryclassandcodeinformation.Class.NAICS' || x.field === 'industryclassandcodeinformation.Class.SIC'
              || x.field === 'industryclassandcodeinformation.Class.PCS' || x.field === 'industryclassandcodeinformation.Class.NTEE') {
              x.operator = 'neq';
            }
          }

        }
      })
    }
    return json;
  }
  convertStringIntoDate(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertStringIntoDate(x)
        }
        else {
          let fieldType = this.filters.find((f: any) => f.field.toLowerCase() === x.field.toLowerCase());
          if (fieldType?.editor == 'date') {
            let formattedDate: any = this.datepipe.transform(x.value, 'MMMM d, y');
            x.value = new Date(formattedDate);
          }
        }
      })
    }
    return json;
  }

  convertDateStringInFormatting(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertDateStringInFormatting(x)
        }
        else {
          if (x.field == 'wbaCreatedDate') {
            x.field = 'wbacreateddate'
          }
          if (x.field == 'effectiveDate' || x.field == 'wbacreateddate') {
            x.value = this.datepipe.transform(x.value, 'yyyy-MM-dd');
          }
        }
      })
    }
    return json;
  }
}
