
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialsComponent } from 'src/app/core/components/dials/dials.component';
import { AddContactComponent } from 'src/app/features/contact/components/add-contact/add-contact.component';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { PaginatorModel } from 'src/app/shared/class/paginatorModel';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { SelectedHeaderService } from 'src/app/shared/services/selected-header.service';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
//import { ContactService } from '../../services/contact.service';
//import { EditContactComponent } from '../edit-contact/edit-contact.component';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor, SortDescriptor, } from '@progress/kendo-data-query';
import { FilterExpression } from '@progress/kendo-angular-filter';
import {
  DataBindingDirective,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ContactService } from 'src/app/features/contact/services/contact.service';
import { CreateListScreen } from '../../../create-list-screen';
import { CreateListService } from '../../../services/CreateList.service';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { SignalRNotifierService } from 'src/app/features/admin/services/Notifier Services/signalR-notifier.service';

@Component({
  selector: 'app-create-list-contacts',
  templateUrl: './create-list-contacts.component.html',
  styleUrls: ['./create-list-contacts.component.css']
})
export class CreateListContactsComponent implements OnInit {
  @ViewChild('templateForState', { static: true })
  public templateForState !: TemplateRef<any>;
  @ViewChild('template2', { static: true })
  public template2 !: TemplateRef<any>;
  @ViewChild('templateForContact', { static: true })
  public templateForContact !: TemplateRef<any>;
  @ViewChild('templateForPolicy', { static: true })
  public templateForPolicy !: TemplateRef<any>;
  @ViewChild('filterDiv') filterDiv!: ElementRef<HTMLInputElement>;
  @ViewChild('templateForNumber', { static: true })
  public templateForNumber!: TemplateRef<any>;
  @ViewChild('templateForNumberEXP', { static: true })
  public templateForNumberEXP!: TemplateRef<any>;
  @ViewChild('templateForClientStatus', { static: true })
  public templateForClientStatus!: TemplateRef<any>;
  @ViewChild('templateForRecallStatus', { static: true })
  public templateForRecallStatus!: TemplateRef<any>;
  @ViewChild('templateForWbaStatus', { static: true })
  public templateForWbaStatus!: TemplateRef<any>;
  @ViewChild('templateForWbaBussinessStatus', { static: true })
  public templateForWbaBussinessStatus!: TemplateRef<any>;
  @ViewChild('templateForPrimaryIndustry', { static: true })
  public templateForPrimaryIndustry!: TemplateRef<any>;
  @ViewChild('templateForSecondaryIndustry', { static: true })
  public templateForSecondaryIndustry!: TemplateRef<any>;
  @ViewChild('templateForProgress', { static: true })
  public templateForProgress !: TemplateRef<any>;
  @ViewChild('templateForResult', { static: true })
  public templateForResult !: TemplateRef<any>;
  @ViewChild('templateprofitCenter', { static: true })
  public templateprofitCenter !: TemplateRef<any>;
  @ViewChild('templateForUserName', { static: true })
  public templateForUserName !: TemplateRef<any>;
  @ViewChild('templateForNumberWithoutComma', { static: true })
  public templateForNumberWithoutComma!: TemplateRef<any>;
  confirmationMsg: any;
  contactInfo: any;
  updateContactForm: any;
  contactList: any = [];
  masterContactList: any = [];
  isDesc: boolean = false;
  column: any;
  errorList: any = [];
  _confirmBox: ConfirmBox;
  _errorBox: ErrorBox;
  _userMessages: UserMessages;
  id: any;
  ListID: any;
  searchText: string = '';
  rowCount = 20;
  _navigationPath: NavigationPath;
  isPrimary: boolean = false;
  sortIcon = 'sort-arw-up';
  contactCount: number = 0;
  _paginator: PaginatorModel;
  totelRecord: any;
  pageNo: number = 1;
  searchGrid: string = '';
  //finalExpValue:any;
  public multiple = false;
  pageTitle: string = 'Create List';
  invalidFilter: boolean = false;
  showError: boolean = false;
  errorMessage: any;
  disableExpirationCalendar: boolean = false;
  dateAfterTwoMnths: Date = new Date();
  ListId: any;
  saveType: any = '';
  showSaveButton: boolean = false;
  showPublishButton: boolean = false;
  _confirmBoxForExceedList!: ConfirmBox;
  minimumDate: Date;
  filteredDataProspectsIds: any[] = [];
  ListDetailData: any = {};
  filterValueCopy: CompositeFilterDescriptor = { logic: 'and', filters: [] };

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  public pageSize = 10;
  public buttonCount = 5;
  public sizes = [10, 25, 50, 100, 250, 500];
  public skip = 0;
  FinalContactList: any = [];
  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  public sort: SortDescriptor[] = [
    {
      field: 'fullName',
      dir: 'asc',
    },
  ];
  public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  filteredDataContactIds: any;
  //masterContactLsist: any[] = [];
  public gridData: unknown[] = this.masterContactList;
  selectedListId: number = 0;
  selectedParPageOption: number = 0;
  isDataAvailable: boolean = false;
  //public filters: FilterExpression[] = [];
  public filters: FilterExpression[] = [
    {
      "field": "fullName",
      "title": "Name",
      "editor": "string"
    }
  ]

  isFilter: boolean = true;
  isFilter1: boolean = false;
  contactType: any;
  totalCount: number = 0;
  $searchingText = new Subject<void>();
  createContactForm: UntypedFormGroup;
  _createListScreen: CreateListScreen;
  isSubmitted: boolean = false;
  hideShowGrid: boolean = false;
  templateList: any;
  templateId: any;
  filterMsg: any;
  showListChangeWarning: boolean = false;
  blankArray: any[] = [];
  actionPerform = "ApplyFilter"
  whitespace: boolean = false;
  statesList: any;
  filterForLob: any[] = [];
  selectedLobFilterValue: any;
  selectedStateFilterValue: any;
  filterValueArrayLob: any[] = [];
  filterValueArrayState: any[] = [];
  filterValueForState: any;
  filterValueForLob: any;
  selectedContactStateFilterValue: any;
  filterValueArraycontactState: any[] = [];
  filterValueForContact: any;
  speciaPolicyType: any;
  clientStatus: any;
  recallForStatusList: any;
  wbaForStatusList: any;
  businessStatusList: any
  gridFilterArrayState: any[] = [];
  prospectIndustryList: any;
  controlId: any;
  policyTypeList: any;
  businessLine: any;
  progress: any[] = [];
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  resultListForGrid: any;
  EpicStatusFilterForResult: any;
  filterBlock: boolean = true;
  profitCenterData: any;
  nextButtonVisiable: boolean = false;
  listType: any
  userList: any;
  constructor(
    private _router: Router,
    private modalService: NgbModal,
    private _contactService: ContactService,
    private _preventBack: PreventBackButtonService,
    private _selectedHeaderService: SelectedHeaderService,
    private fb: UntypedFormBuilder,
    private _customNotifireService: CustomNotifireService,
    public ValidateKey: KeyboardValidation,
    public datepipe: DatePipe,
    private _saveJsonService: CreateListService,
    private _ProspectListService: ProspectListService,
    private renderer: Renderer2,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    public activeModal: NgbActiveModal,
    private _createListService: CreateListService,
    private _AddprospectService: AddprospectService,
    private _addWBAService: WhiteBoardActivityService,
    private signalRNotifire: SignalRNotifierService,
    private ref: ChangeDetectorRef
  ) {
    this.dateAfterTwoMnths.setDate((new Date).getDate() + 60);
    this.minimumDate = new Date();
    this.minimumDate.setDate(this.minimumDate.getDate() + 1);
    this._navigationPath = new NavigationPath();
    sessionStorage.removeItem('contactId');
    sessionStorage.removeItem('prospectId');
    this._confirmBox = new ConfirmBox();
    this._confirmBoxForExceedList = new ConfirmBox();
    this._errorBox = new ErrorBox();
    this._userMessages = new UserMessages();
    this._paginator = new PaginatorModel();
    this._createListScreen = new CreateListScreen();
    this.$searchingText.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.getContactList(this.selectedListId);
    });
    this.createContactForm = this.fb.group({
      listName: ['', [Validators.required, Validators.maxLength(100)]],
      listType: ['Static', [Validators.required]],
      template: [''],
      expirationDate: ['']

    })
  }

  ngOnInit(): void {
    this.controlId = this.newGuid();
    this.ListID = sessionStorage.getItem('listId')
    //checking form is opened in edit mode
    this.showListChangeWarning = this._router.url.includes('edit-list-contact')
    if (this.showListChangeWarning) {
      sessionStorage.setItem('editMode', 'edit');
    }
    //this.changeDetector();
    this.ListId = sessionStorage.getItem('manageListId');
    this.createContactForm.get('expirationDate')?.setValue(this.dateAfterTwoMnths);
    // this.getTemplates();
    this._preventBack.preventBackButton(); //method to prevent browser back button
    this._customNotifireService.setActiveComponent('Contact');
    this.getAllMasters();
    // this.getContactFilterJSON();
    // this.getProspectStates();
    // this.getBusinessStatusList();
    // this.getProspectIndustry();
    // this.getBusinessLineList();
    // this.getPolicyTypeList();
    this.getUserList();
    //signalR code end
  }

  getAllMasters() {
    let getTemplates = this._saveJsonService.getContactTemplates()
    let contactFilter = this._contactService.getContactFilterJSON();
    let statesList = this._AddprospectService.getProspectStates();
    let businessStatus = this._addWBAService.getBusinessStatusList();
    let ProspectIndustry = this._AddprospectService.getProspectIndustry();
    let businessLine = this._addWBAService.getBusinessLineList();
    let policyType = this._addWBAService.getPolicyTypeList(5);
    var getProgressList = this._addWBAService.getprogressList();

    forkJoin([getTemplates, contactFilter, statesList, businessStatus, ProspectIndustry, businessLine, policyType, getProgressList]).subscribe(resp => {
      if (resp) {
        this.templateList = resp[0].list;
        this.filters = resp[1].masterFilterOptionList;
        this.statesList = resp[2].statesList;
        this.businessStatusList = resp[3].businessStatusList;
        this.prospectIndustryList = resp[4].prospectIndustryList;
        this.businessLine = resp[5].businessLineList;
        this.policyTypeList = resp[6].businessLineList;
        this.progress = resp[7].progressList;
        if (this.ListId > 0) {
          this.pageTitle = 'Edit List'
          this.getQueryById(this.ListId)
        }
        this.getContactFilterJSON();
      }
    },
      (error) => {
        let obj = error.error.errors;
        if (obj) {
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
          this.errorList = arr;
        }
      });
  }

  public getContactFilterJSON(): void {
    // this._contactService.getContactFilterJSON().subscribe((response: any) => {
    //   this.filters = response.masterFilterOptionList;
    this.clientStatus = ['Not a client', 'Client', 'Lost Client'];
    this.recallForStatusList = ['Open', 'Completed', 'Expired'];
    this.wbaForStatusList = ['Open', 'Won', 'Lost'];
    this.resultListForGrid = ['Bound', 'Not Quoted,Not Bound', 'Not Renewed', 'Quoted,Not Bound', 'Open'];
    this.profitCenterData = ['Lamb', 'CREIS', 'TruePartners', 'TruePartners - Wheels']
    this.filters.forEach((element: any) => {
      if (element.field == 'State') {
        element.operators = ["eq"];
      }
      else if (
        element.field == 'ProspectInformation.NoOfEmployees' ||
        element.field == 'ProspectInformation.Revenue' ||
        element.field == 'ProspectInformation.PackagePremium' ||
        element.field == 'ProspectInformation.WorkersCompensationPremium' ||
        element.field == 'WBA.TargetPremium' ||
        element.field == 'WBAOutComeDetail.BoundRevenue' ||
        element.field == 'Prospect.TotalRevenue' ||
        element.field == 'Prospect.DispositionCount'
      ) {
        element.editorTemplate = this.templateForNumber;
      }
      else if (
        element.field == 'ProspectInformation.EXPMOD'
      ) {
        element.editorTemplate = this.templateForNumberEXP;
      }
      else if (
        element.field == 'Contact.ProspectId'
      ) {
        element.editorTemplate = this.templateForNumberWithoutComma;
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'WBALine.Line') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'Office1State') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'MasterPolicyType.line') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'prospectstatus.Status') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'ContactRecall.Status') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'WBAStatusId') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'WBA.MasterBusinessStatus') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'ProspectIndustry.IndustryName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'PI.IndustryName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'ProfitCenter') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'pmv_view.TagName' || element.field == 'Tag.Name') {
        element.operators = ["contains", "eq", "neq", "doesnotcontain", "isnull"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'industryclassandcodeinformation.Class.NAICS') {
        element.operators = ["contains", "eq", "neq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'industryclassandcodeinformation.Class.SIC') {
        element.operators = ["contains", "eq", "neq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'industryclassandcodeinformation.Class.NTEE') {
        element.operators = ["contains", "eq", "neq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'industryclassandcodeinformation.Class.PCS') {
        element.operators = ["contains", "eq", "neq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'ContactProgress.ProgressName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'ContactEpicStatus.EpicStatusName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'WBA.CreatedBy') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'Prospect.CreatedBy') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'Prospect.LastContactedBy') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'Prospect.ModifiedBy') {
        element.operators = ["eq"];
      }
    });

    this.filters.forEach((element: any) => {
      if (element?.field == 'WBA.MarketingManagerId') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'WBA.OwnerUserName') {
        element.operators = ["eq"];
      }
    });
    this.filters.forEach((element: any) => {
      if (element?.field == 'wba.OriginalProducerName') {
        element.operators = ["eq"];
      }
    });
    let getStateIndex = this.filters.findIndex((x: any) => x.field == 'State')!
    let getLobIndex = this.filters.findIndex((x: any) => x.field == 'WBALine.Line')!
    this.filters[getStateIndex].editorTemplate = this.templateForState;
    this.filters[getLobIndex].editorTemplate = this.template2;
    let getContactStateIndex = this.filters.findIndex((x: any) => x.field == 'Office1State')!
    this.filters[getContactStateIndex].editorTemplate = this.templateForContact;
    let getspecialPolicyIndex = this.filters.findIndex((x: any) => x.field == 'MasterPolicyType.line')!
    this.filters[getspecialPolicyIndex].editorTemplate = this.templateForPolicy;
    let getspecialClientStatus = this.filters.findIndex((x: any) => x.field == 'prospectstatus.Status')!
    this.filters[getspecialClientStatus].editorTemplate = this.templateForClientStatus;
    let getRecallStatus = this.filters.findIndex((x: any) => x.field == 'ContactRecall.Status')!
    this.filters[getRecallStatus].editorTemplate = this.templateForRecallStatus;
    let getWbaStatus = this.filters.findIndex((x: any) => x.field == 'WBAStatusId')!
    this.filters[getWbaStatus].editorTemplate = this.templateForWbaStatus;
    let getWbaBussinessStatus = this.filters.findIndex((x: any) => x.field == 'WBA.MasterBusinessStatus')!
    this.filters[getWbaBussinessStatus].editorTemplate = this.templateForWbaBussinessStatus;
    let getPrimaryIndustry = this.filters.findIndex((x: any) => x.field == 'ProspectIndustry.IndustryName')!
    this.filters[getPrimaryIndustry].editorTemplate = this.templateForPrimaryIndustry;
    let getSecondaryIndustry = this.filters.findIndex((x: any) => x.field == 'PI.IndustryName')!
    this.filters[getSecondaryIndustry].editorTemplate = this.templateForSecondaryIndustry;
    let getspecialProgressIndex = this.filters.findIndex((x: any) => x.field == 'ContactProgress.ProgressName')!
    this.filters[getspecialProgressIndex].editorTemplate = this.templateForProgress;
    let getspecialResultIndex = this.filters.findIndex((x: any) => x.field == 'ContactEpicStatus.EpicStatusName')!
    this.filters[getspecialResultIndex].editorTemplate = this.templateForResult;
    let getprofitCenterIndex = this.filters.findIndex((x: any) => x.field == 'ProfitCenter')!
    this.filters[getprofitCenterIndex].editorTemplate = this.templateprofitCenter;
    let getProspectCreatedBy = this.filters.findIndex((x: any) => x.field == 'Prospect.CreatedBy')!
    this.filters[getProspectCreatedBy].editorTemplate = this.templateForUserName;
    let getWBACreatedBy = this.filters.findIndex((x: any) => x.field == 'WBA.CreatedBy')!
    this.filters[getWBACreatedBy].editorTemplate = this.templateForUserName;
    let getProspectLastContactedBy = this.filters.findIndex((x: any) => x.field == 'Prospect.LastContactedBy')!
    this.filters[getProspectLastContactedBy].editorTemplate = this.templateForUserName;
    let getProspectLastModifiedBy = this.filters.findIndex((x: any) => x.field == 'Prospect.ModifiedBy')!
    this.filters[getProspectLastModifiedBy].editorTemplate = this.templateForUserName;

    let getWBAMarketingManagerId = this.filters.findIndex((x: any) => x.field == 'WBA.MarketingManagerId')!
    this.filters[getWBAMarketingManagerId].editorTemplate = this.templateForUserName;
    let getWBAOwnerUserName = this.filters.findIndex((x: any) => x.field == 'WBA.OwnerUserName')!
    this.filters[getWBAOwnerUserName].editorTemplate = this.templateForUserName;
    let getwbaOriginalProducerName = this.filters.findIndex((x: any) => x.field == 'wba.OriginalProducerName')!
    this.filters[getwbaOriginalProducerName].editorTemplate = this.templateForUserName;
    // });
    this.createContactForm.get('template')?.disable();
  }

  getProspectStates(): void {
    this._AddprospectService.getProspectStates().subscribe((response: any) => {
      this.statesList = response.statesList

    });
  }
  getBusinessStatusList(): void {
    this._addWBAService.getBusinessStatusList().subscribe((response: any) => {
      this.businessStatusList = response.businessStatusList;
    });
  }
  getProspectIndustry(): void {
    this._AddprospectService.getProspectIndustry().subscribe((response: any) => {
      this.prospectIndustryList = response.prospectIndustryList;
    });
  }

  //to get policy Type list for speciality
  getPolicyTypeList() {
    this._addWBAService.getPolicyTypeList(5).subscribe(resp => {
      this.policyTypeList = resp.businessLineList;
    })
  }

  //to get Business Line list
  getBusinessLineList() {
    this._addWBAService.getBusinessLineList().subscribe(resp => {
      this.businessLine = resp.businessLineList;
    })
  }



  //Line of business select dropdown
  public onChangeForLob(event: any) {
    this.selectedLobFilterValue = event.toString();
  }


  public onChangeForState(event: any) {
    //state is not in filter
    this.selectedStateFilterValue = event.toString()
  }

  //contact State select dropdown
  public onChangeForContactState(event: any) {
    this.selectedContactStateFilterValue = event.toString()
  }

  public onChange(event: any) {
    this.actionPerform = "FilterChange";
    // this.gridFilterArrayState = event;
    if (event.length > 0 && event[0] == '') event.splice(0, 1);
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "office1State")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "office1State",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "office1State",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
    this.$searchingText.next();
  }


  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }
  onlistTypeChange(type: string) {
    this.listType = type;
    this.nextButtonVisiable = false;
    this.isDataAvailable = false;
    this.hideShowGrid = false;
    if (type == "Static") {
      this.disableExpirationCalendar = false;
      this.showSaveButton = false;
      this.showPublishButton = false;
      this.createContactForm.get('expirationDate')?.setValue(this.dateAfterTwoMnths);
    } else if (type == "Dynamic") {
      this.disableExpirationCalendar = true;
      this.showSaveButton = false;
      this.showPublishButton = false;
      this.createContactForm.get('expirationDate')?.setValue('');
    } else if (type == "System-Dafault") {
      this.showSaveButton = true;
      this.showPublishButton = false;
      this.disableExpirationCalendar = true;
      this.createContactForm.get('expirationDate')?.setValue('');

    } else if (type == "isMarketing") {
      this.showSaveButton = false;
      this.showPublishButton = true;
      this.disableExpirationCalendar = false;
      this.createContactForm.get('expirationDate')?.setValue(this.dateAfterTwoMnths);

    }
    else {
      //template
      this.disableExpirationCalendar = true;
      this.showSaveButton = true;
      this.showPublishButton = false;
      this.createContactForm.get('expirationDate')?.setValue('');


    }
  }
  onTemplateChange(event: any) {
    this.templateId = event.target.value.split(': ').pop();;
    if (this.templateId == "") {
      this.createContactForm.controls['listType'].patchValue('Static');
      this.createContactForm.controls['listName'].patchValue('');
      this.filterValue = { logic: 'and', filters: [] };
      this.filterValueCopy = { logic: 'and', filters: [] };
      this.filterValue.filters.length > 0 ? JSON.stringify(this.filterValue) : ""
      this.filterValueCopy.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : ""
      this.getContactList(0);
    }

    if (this.templateId > 0) {
      this.getTemplateJSON(this.templateId)
    }
  }

  onTemplateCheck(event: any) {
    if (event.target.checked == true) {
      this.createContactForm.get('template')?.enable();
    } else {
      this.filterValue = { logic: 'and', filters: [] };
      this.filterValue.filters.length > 0 ? JSON.stringify(this.filterValue) : ""
      if (this.templateId > 0) {
        this.templateId = 0;
        this.getContactList(0);
      }
      this.createContactForm.get('template')?.disable();
      this.createContactForm.get('template')?.setValue('');
    }
  }
  //get list details By id
  getQueryById(id: any) {
    this._ProspectListService.getContactById(id).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listContactFilter;
          this.setFormData();
        }
      }),
      error: (() => {

      })
    })
  }

  //get Template details By id
  getTemplateJSON(id: any) {
    this._ProspectListService.getContactById(id).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.listContactFilter;
          this.filterValue = JSON.parse(this.ListDetailData.filterJson);
          this.filterValueCopy = JSON.parse(this.ListDetailData.filterJson);
          this.convertContainsIntoEquals(this.filterValue);
          this.getContactList(id)

        }
      }),
      error: (() => {

      })
    })
  }
  getTemplates() {
    this._saveJsonService.getContactTemplates().subscribe((resp: any) => {
      this.templateList = resp.list;
    })
  }


  DateFormattingInAllGroups(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.DateFormattingInAllGroups(x)
        }
        else {
          let fieldType = this.filters.find((f: any) => f.field.toLowerCase() === x.field.toLowerCase())?.editor;
          if (fieldType) {
            if (fieldType?.toLowerCase() == 'date') {
              // var myDate = new Date(x.value).toISOString();
              x.value = this.datepipe.transform(x.value, 'yyyy-MM-dd');
            }
          }
        }
      })
    }
  }

  changeDetector() {
    this.ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 2000);
  }

  // Telrik functions start
  applyFilter(value: CompositeFilterDescriptor, val: boolean): void {
    this.filterBlock = false;
    this.ref.detectChanges();
    this.filterValueCopy = JSON.parse(JSON.stringify(value));
    this.actionPerform = 'ApplyFilter';
    this.DateFormattingInAllGroups(this.filterValueCopy)
    this.pageNo = 1;
    this.invalidFilter = false;
    this.skip = 0;
    this.totalCount = 0;
    this.filterValue = value;
    this.getContactList(this.selectedListId);
    this.isFilter1 = !val;

  }

  checkBlankSpace(event: any) {
    if (event.target.value.trim().length == 0) {
      this.whitespace = false;
    }
  }

  searchValue(inputValue: any) {
    this.searchGrid = inputValue;
  }

  //searching on all columns
  onFilter(): void {
    if (this.searchGrid === "") {
      return
    }
    else {
      this.actionPerform = 'searchAll'
      this.pageNo = 1;
      this.skip = 0;
      this.totalCount = 0;
      this.getContactList(this.selectedListId);
    }
  }
  filterChange(filter: CompositeFilterDescriptor): void {
    // filter.filters.map((obj: any) => {
    //   //finding fieldType in master array
    //   let fieldType = this.filters.find((x: any) => x.field.toLowerCase() == obj.field.toLowerCase())?.editor;
    //   if (fieldType?.toLowerCase() == 'date') obj.value = this.datepipe.transform(obj.value, 'yyyy-MM-dd');
    // })
    this.actionPerform = "FilterChange"
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
    this.gridFilter = { ...filter };

    if (this.gridFilter.filters.length > 0) {
      // Loop through each filter to check and clean phone numbers
      this.gridFilter.filters.forEach((f: any) => {
        f.filters.forEach((element: any) => {
          if (element.field === 'office1PhoneNumber') {
            element.value = this.cleanPhoneNumber(element.value);
          }
        });

      });
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "office1State")
      if (stateIndex > -1 && this.gridFilterArrayState.length == 0)
        this.gridFilter.filters.splice(stateIndex, 1)
      if (stateIndex < 0) this.gridFilterArrayState = [];
    }
    if (!this.gridFilter.filters.length) {
      this.gridFilterArrayState = [];
    }

    if (this.gridFilterArrayState != undefined && this.gridFilterArrayState.length > 0)
      this.onChange(this.gridFilterArrayState);

    this.$searchingText.next();
  }

  private cleanPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/[\(\)\-\s]/g, '');
  }

  getContactList(listId: number, signalR = false) {
    let data;
    let __tempFilter: any = this.convertArrayToString(this.filterValueCopy);
    let _tempFilter: any = this.convertEqualsIntoContains(__tempFilter);
    this.filterValueCopy = this.convertStringToBool(this.filterValueCopy);
    let tempgridFilter = JSON.parse(JSON.stringify(this.gridFilter));
    tempgridFilter = this.convertDateStringInFormatting(tempgridFilter)
    if (listId == 0) {
      data = {
        "listId": 0,
        "pageNumber": this.pageNo,
        "pageSize": this.pageSize,
        "totalCount": signalR ? -1 : this.totalCount,
        "sortDir": this.sort[0].dir,
        "sortCol": this.sort[0].field,
        "searchText": this.searchGrid,
        "filterJson": _tempFilter.filters != null && _tempFilter.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : '',
        "gridFilterJson": tempgridFilter?.filters != null && tempgridFilter?.filters?.length > 0 ? JSON.stringify(tempgridFilter) : '',
        "UserId": this.controlId

      }
    } else {
      data = {
        "listId": listId,
        "pageNumber": this.pageNo,
        "pageSize": this.pageSize,
        "totalCount": signalR ? -1 : 0,
        "sortDir": this.sort[0].dir,
        "sortCol": this.sort[0].field,
        "searchText": this.searchGrid,
        "filterJson": _tempFilter.filters != null && _tempFilter.filters.length > 0 ? JSON.stringify(this.filterValueCopy) : '',
        "gridFilterJson": tempgridFilter?.filters != null && tempgridFilter?.filters?.length > 0 ? JSON.stringify(tempgridFilter) : '',
        "UserId": this.controlId
      }
    }
    if (listId.toString() == '0' && data.totalCount == 0) this.getContactList(listId, true);
    this._contactService.contactPagination(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp: any) => {
      if (resp) {
        this.filterBlock = true;
        if (!signalR) {
          this.masterContactList = resp.items[0].contactList;
        }
        if (signalR) {
          this.FinalContactList.total = resp.totalRecord;
          if (signalR && resp.totalRecord == 0) { this.FinalContactList.data = []; this.ngUnsubscribe.next(); }
        }
        else {
          this.FinalContactList.data = this.masterContactList;
          this.FinalContactList.total = resp.totalRecord > 0 ? resp.totalRecord : this.FinalContactList.total;
        }
        if (this.actionPerform == 'ApplyFilter' && this.masterContactList.length > 0) {
          this.hideShowGrid = false
          this.isDataAvailable = true;
          return;
        }
        if (this.actionPerform == 'ApplyFilter' && this.masterContactList.length == 0) {
          this.hideShowGrid = true;
          this.searchGrid = "";
          this.isDataAvailable = false;
          this.filterMsg = "No Data Available";
          this.nextButtonVisiable = false;
          if (this.listType == "Dynamic") {
            this.nextButtonVisiable = true;
            this.isDataAvailable = false;
            this.hideShowGrid = true;
          }
          return;
        }
        if (this.actionPerform == 'FilterChange') {
          this.hideShowGrid = false
        }
        if (this.masterContactList.length > 0 && this.actionPerform == 'searchAll') {
          this.isDataAvailable = true;
          this.renderer.removeClass(this.filterDiv.nativeElement, 'show');
          return;
        }
        if (this.masterContactList.length == 0 && this.actionPerform == 'searchAll') {
          this.renderer.addClass(this.filterDiv.nativeElement, 'show');
          return;
        }
      }
    },
      (error: any) => {
        this.filterBlock = true;
        let obj = error.error.errors;
        if (obj) {
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
          this.errorList = arr;
        }
      },
    );
  }

  setFormData() {
    this.invalidFilter = false
    let listType = 'Static'
    //condition applied because test cases was giving error
    if (this.ListDetailData) {
      if (this.ListDetailData.listType == 'QUERY' && this.ListDetailData.saveAs == 'Draft') {
        listType = 'Dynamic'
      }
      if (this.ListDetailData.listType == 'QUERY' && this.ListDetailData.saveAs == 'Published') {
        listType = 'Dynamic'
      }
      if (this.ListDetailData.listType == 'QUERY' && this.ListDetailData.saveAs == 'Template') {
        listType = 'Template'
        this.showSaveButton = true;
      }
      if (listType == "Static") {
        this.disableExpirationCalendar = false;
        this.createContactForm.get('expirationDate')?.setValue(this.dateAfterTwoMnths);
      } else {
        this.disableExpirationCalendar = true;
        this.createContactForm.get('expirationDate')?.setValue('');
      }

      this.createContactForm.controls['listType'].patchValue(listType);
      this.createContactForm.controls['listName'].patchValue(this.ListDetailData.listName);
      if (this.ListDetailData.expirationDate != null) {
        this.createContactForm.controls['expirationDate'].patchValue(new Date(this.ListDetailData.expirationDate));
      }
      // this.filterValue = JSON.parse(this.ListDetailData.filterJson)
      if (this.ListDetailData.filterJson) {
        this.filterValue = JSON.parse(this.ListDetailData.filterJson);
        this.filterValueCopy = JSON.parse(this.ListDetailData.filterJson);
        this.convertContainsIntoEquals(this.filterValue);
        this.selectedStateFilterValue = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "State")[0]?.value;
        this.selectedLobFilterValue = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "WBALine.Line")[0]?.value;
        this.filterValueForState = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "State")[0]?.value;
        this.filterValueArrayState = this.filterValueForState?.split(',')
        this.filterValueForLob = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "WBALine.Line")[0]?.value
        this.filterValueArrayLob = this.filterValueForLob?.split(',');
        this.filterValueForContact = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "Office1State")[0]?.value
        this.filterValueArraycontactState = this.filterValueForContact?.split(',');
        this.EpicStatusFilterForResult = JSON.parse(this.ListDetailData.filterJson).filters.filter((x: any) => x.field == "ContactEpicStatus.EpicStatusName")[0]?.value;
        if (this.EpicStatusFilterForResult == "Null") {
          this.EpicStatusFilterForResult = "Open"
        }
        if (this.EpicStatusFilterForResult) {
          let commaValue1 = false, commaValue2 = false;
          if (this.EpicStatusFilterForResult.includes('Not Quoted,Not Bound')) {
            this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/Not Quoted,Not Bound/g, "");
            if (this.EpicStatusFilterForResult.includes(',,')) {
              this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/,,/g, ",");
            }
            commaValue1 = true;
          }
          if (this.EpicStatusFilterForResult.includes('Quoted,Not Bound')) {
            this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/Quoted,Not Bound/g, "");
            if (this.EpicStatusFilterForResult.includes(',,')) {
              this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/,,/g, ",");
            }
            commaValue2 = true;
          }
          this.EpicStatusFilterForResult = this.EpicStatusFilterForResult.replace(/(\s*,?\s*)*$/, "");

          this.filterValue.filters.forEach((x: any) => {
            if (x.field == "ContactEpicStatus.EpicStatusName") {
              x.value = this.EpicStatusFilterForResult?.split(',');
              if (commaValue1) {
                x.value.push("Not Quoted,Not Bound")
              }
              if (commaValue2) {
                x.value.push("Quoted,Not Bound")
              }
            }
          })

        }
      }
    }
    //getting prosects by listId
    this.getContactList(this.ListId)

  }

  convertDateStringInFormatting(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertDateStringInFormatting(x)
        }
        else {
          if (x.field == 'createdDate') {
            x.value = this.datepipe.transform(x.value, 'yyyy-MM-dd');
          }
        }
      })
    }
    return json;
  }

  sendData(type: any) {
    this.isSubmitted = true
    this.saveType = type;
    if (this.filterValue.filters.length == 0) {
      this.invalidFilter = true
      return
    }
    if (!this.createContactForm.valid) {
      return
    }
    if (this.f.listName.value.trim().length === 0) {
      this.whitespace = true
      return
    }
    //for edit mode
    if (this.showListChangeWarning) {
      // this.cancel()
      this.saveDataApiCall();
    } else {
      //for create mode
      this.saveDataApiCall();

    }
  }

  getUserList() {
    let data = {
      "lookUpType": 'AllUser',
      "rowCount": 500
    }
    this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((resp: any) => {
      this.userList = resp.userList;
    })
  }


  saveDataApiCall() {
    this.isSubmitted = true;
    let __tempFilter: any = this.convertArrayToString(this.filterValueCopy);
    let _tempFilter: any = this.convertEqualsIntoContains(__tempFilter);;
    this.filterValueCopy = this.convertStringToBool(this.filterValueCopy);
    // if (this.FinalContactList.total > 20000 && this.f.listType.value == 'Static') {
    //   //show popup
    //   this._confirmBoxForExceedList.message = this._userMessages.openPopListMessage;
    //   this._confirmBoxForExceedList.isVisible = true;
    //   return
    // }
    if (this.filterValue.filters.length == 0) {
      this.invalidFilter = true
      return
    }
    if (!this.createContactForm.valid) {
      return
    }
    let listType = 'Static'
    if (this.f.listType.value == 'Dynamic' || this.f.listType.value == 'System-Default' || this.f.listType.value == 'Template') {
      listType = 'Query'
    }
    let saveAs = 'Draft'
    if (this.f.listType.value == 'System-Default') {
      saveAs = 'System-Default'
    }
    if (this.f.listType.value == 'Template') {
      saveAs = 'Template'
    }

    let data = {
      "listFilter": {
        listId: this.ListId,
        ListEntityType: "Contact",
        ListType: listType,
        SaveAs: saveAs,
        TemplateId: this.templateId,
        IsMarketingList: this.f.listType.value == "isMarketing" ? true : false,
        expirationDate: this.datepipe.transform(this.f.expirationDate.value, 'yyyy-MM-dd')!,
        listName: this.f.listName.value,
        filterJson: JSON.stringify(this.filterValueCopy),
        totalCount: this.FinalContactList.total
      }
    };

    this.errorList = []

    this._saveJsonService.saveContactList(data).subscribe((resp: any) => {
      if (this.errorList.length == 0) {
        this.showError = false;

        sessionStorage.setItem('listId', resp);
        //again setting Query to Dynamic for list type display
        if (data.listFilter.ListType == 'Query') {
          data.listFilter.ListType = 'Dynamic'
        }
        sessionStorage.setItem('manageListId', resp);

        this.createContactForm.controls['listName'].patchValue('');
        this.filterValue = { logic: 'and', filters: [] };
        this.createContactForm.controls.listName?.setValidators(null);
        this.createContactForm.controls.listName?.updateValueAndValidity();
        if (this.f.listType.value == 'Static')
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'contact-share-user-list');
        else if (this.f.listType.value == 'Dynamic')
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'create-share-user-list');
        else {
          sessionStorage.removeItem('manageListId');
          this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
        }
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorMessage = "Error : " + arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {

            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  }



  closeError() {
    this.showError = false;
  }

  navigate(id: any, prospectid: any, comp: any) {
    sessionStorage.setItem('contactId', id);
    let viewDetailsData = {
      prospectId: prospectid,
      listTitle: 'All Prospects'
    }
    sessionStorage.setItem('viewDetailsData', JSON.stringify(viewDetailsData));

    if (comp == this._navigationPath.getContactsViewUrl()) {
      this._router.navigateByUrl('/' + this._navigationPath.getContactsUrl() + '/' + comp);
    } else {
      this._customNotifireService.setActiveComponent('Prospect');
      this._selectedHeaderService.setSelectedHeader(
        this._navigationPath.getProspectsUrl()
      );
      this._router.navigateByUrl('/' + this._navigationPath.getProspectsUrl() + '/' + comp);
    }
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
  }


  openPopUpForPublish() {
    this.isSubmitted = true
    if (this.filterValue.filters.length == 0) {
      this.invalidFilter = true
      return
    }
    if (!this.createContactForm.valid) {
      return
    }
    if (!this.createContactForm.dirty) {
      this.closeModal();
    } else {
      this._confirmBox.message1 = this._userMessages.cancelButtonMessage1;
      this._confirmBox.isVisible1 = true;
    }
  }

  savePublishList() {
    this.isSubmitted = true
    let __tempFilter: any = this.convertArrayToString(this.filterValueCopy);
    let _tempFilter: any = this.convertEqualsIntoContains(__tempFilter);;
    this.filterValueCopy = this.convertStringToBool(this.filterValueCopy);
    if (this.filterValue.filters.length == 0) {
      this.invalidFilter = true
      return
    }
    if (!this.createContactForm.valid) {
      return
    }
    let listType = 'Static'
    if (this.f.listType.value == 'Dynamic' || this.f.listType.value == 'System-Default' || this.f.listType.value == 'Template') {
      listType = 'Query'
    }
    let saveAs = 'Draft'
    if (this.f.listType.value == 'System-Default') {
      saveAs = 'System-Default'
    }
    if (this.f.listType.value == 'Template') {
      saveAs = 'Template'
    }

    let data = {
      "listFilter": {
        listId: this.ListId,
        ListEntityType: "Contact",
        ListType: listType,
        SaveAs: saveAs,
        TemplateId: this.templateId,
        IsMarketingList: this.f.listType.value == "isMarketing" ? true : false,
        expirationDate: this.datepipe.transform(this.f.expirationDate.value, 'yyyy-MM-dd')!,
        listName: this.f.listName.value,
        filterJson: JSON.stringify(this.filterValueCopy)
      }
    };
    //calling publish api
    this._saveJsonService.saveContactList(data).subscribe((resp: any) => {

      if (resp) {
        this._createListService.publishContactList(resp).subscribe((resp: any) => {
          if (resp) {
            if (data.listFilter.SaveAs == 'Draft') {
              data.listFilter.SaveAs = 'Marketing-List'
            }
            this._router.navigateByUrl('/' + this._navigationPath.getListManagementUrl() + '/' + 'manage-list');
          }
        })
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          this.errorList = [];
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {
            this.showError = true;
            this.errorMessage =
              'Error : ' + err.message + ', Status: ' + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
        this._confirmBox.isVisible1 = false;
      });
  }

  closeModal() {
    this.activeModal.close();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = this.FinalContactList.total
    this.sort = sort;
    this.getContactList(this.selectedListId);
  }

  trackByContactId(item: any) {
    return item.id;
  }

  contactPaginate(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNo = (event.take + event.skip) / event.take;
    this.totalCount = this.FinalContactList.total
    this.getContactList(this.selectedListId)
  }

  get f(): any {
    return this.createContactForm?.controls;
  }

  //To hide column filters
  columnFilter(value: boolean) {
    this.isFilter = !value;
  }

  //multiselect ngmodel
  getMultiselectValue(currentItem: any) {
    typeof currentItem.value == 'string' && currentItem.value != ""
      ? (currentItem.value = currentItem.value.split(','))
      : currentItem.value;
    return currentItem.value;
  }

  getMultiselectValueChange(currentItem: any) {
    return currentItem;
  }

  //multiselect for industry
  getMultiselectValueByid(currentItem: any) {
    typeof currentItem.value == 'string' && currentItem.value != ""
      ? (currentItem.value = currentItem.value.split(',').map(Number))
      : currentItem.value;
    return currentItem.value;
  }

  //numerical ngmodel
  getNumericValue(currentItem: any) {
    if (currentItem.value) {
      return +currentItem.value;
    } else {
      return currentItem.value;
    }
  }

  getNumericValueEXP(currentItem: any) {

    if (currentItem.value) {
      //let expValue = this.ValidateKey.formatDecimalNumber(currentItem.value.toString());
      let _curValue = currentItem.value.toString();
      let index0 = _curValue.charAt(0)
      let index1 = '.'
      let index2 = _curValue.charAt(_curValue.includes('.') ? 2 : 1)
      let index3 = _curValue.charAt(_curValue.includes('.') ? 3 : 2)
      let expValue = index0 + index1 + index2 + index3;
      return (+expValue);
    } else {
      return currentItem.value;
    }
  }

  //convert decimal to int
  public onChangeForNumber(
    event: any,
    currentItem: FilterDescriptor,
  ) {
    currentItem.value = event.toFixed();
  }

  //multiselect select dropdown
  public onChangeForMultiselect(
    event: any,
    currentItem: FilterDescriptor,
  ) {
    currentItem.value = event;
  }

  //covert array to string
  convertArrayToString(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertArrayToString(x);
        } else {
          x.value = x?.value?.toString();
        }
      });
    }
    return json;
  }
  //convert string to bool
  convertStringToBool(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertStringToBool(x);
        } else {
          x.value = (x.value === 'true') ? true : (x.value === 'false') ? false : x.value;
        }
      });
    }
    return json;
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });

  }

  //convert eq operator to contains operator
  convertEqualsIntoContains(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertEqualsIntoContains(x)
        }
        else {
          if (x.operator == 'eq') {
            if (x.field === 'State' || x.field === 'WBA.MasterBusinessStatus' || x.field === 'WBAStatusId'
              || x.field === 'ContactRecall.Status' || x.field === 'prospectstatus.Status' || x.field === 'MasterPolicyType.line'
              || x.field === 'Office1State' || x.field === 'WBALine.Line' || x.field === 'ProspectIndustry.IndustryName'
              || x.field === 'PI.IndustryName' || x.field === 'ContactProgress.ProgressName' || x.field === 'ContactEpicStatus.EpicStatusName' || x.field === 'ProfitCenter'
              || x.field == 'wba.OriginalProducerName' || x.field == "Prospect.ModifiedBy" || x.field == "WBA.MarketingManagerId" || x.field == "Prospect.LastContactedBy" || x.field === "WBA.OwnerUserName" || x.field == 'Prospect.CreatedBy' || x.field == 'WBA.CreatedBy') {
              x.operator = 'contains';
            }
          } else if (x.operator == 'neq') {
            if (x.field === 'industryclassandcodeinformation.Class.NAICS' || x.field === 'industryclassandcodeinformation.Class.SIC'
              || x.field === 'industryclassandcodeinformation.Class.PCS' || x.field === 'industryclassandcodeinformation.Class.NTEE') {
              x.operator = 'doesnotcontain'
            }
          }
        }
      })
    }
    return json;
  }

  //convert contains operator to eq operator
  convertContainsIntoEquals(json: any) {
    if (json) {
      json.filters.forEach((x: any) => {
        if (x.hasOwnProperty('filters')) {
          this.convertContainsIntoEquals(x)
        }
        else {
          if (x.operator == 'contains') {
            if (x.field === 'State' || x.field === 'WBA.MasterBusinessStatus' || x.field === 'WBAStatusId'
              || x.field === 'ContactRecall.Status' || x.field === 'prospectstatus.Status' || x.field === 'MasterPolicyType.line'
              || x.field === 'Office1State' || x.field === 'WBALine.Line' || x.field === 'ProspectIndustry.IndustryName'
              || x.field === 'PI.IndustryName' || x.field === 'ContactProgress.ProgressName' || x.field === 'ContactEpicStatus.EpicStatusName' || x.field === 'ProfitCenter'
              || x.field == 'wba.OriginalProducerName' || x.field == 'Prospect.ModifiedBy' || x.field == 'WBA.MarketingManagerId' || x.field == "Prospect.LastContactedBy" || x.field === 'WBA.OwnerUserName' || x.field == 'Prospect.CreatedBy' || x.field == 'WBA.CreatedBy') {
              x.operator = 'eq';
            }
          } else if (x.operator == 'doesnotcontain') {
            if (x.field === 'industryclassandcodeinformation.Class.NAICS' || x.field === 'industryclassandcodeinformation.Class.SIC'
              || x.field === 'industryclassandcodeinformation.Class.PCS' || x.field === 'industryclassandcodeinformation.Class.NTEE') {
              x.operator = 'neq';
            }
          }
        }
      })
    }
    return json;
  }
}

