<!-- Telrik implemented start -->
<div class="main-body-con">
    <div class="page-container pt-4">
        <div class="page-hdr-outer ps-4 pe-4">
            <form novalidate [formGroup]="createWhiteboardForm">
                <div class="hd-list">
                    <div class="page-hdr">
                        <h2>{{pageTitle}} - Whiteboard</h2>
                        <!-- <a href="javascript:void(0)" [routerLink]="['../manage-list']" title="close"
                            class="closebtn"><img src="assets/images/close.svg" alt="" /></a> -->
                        <div class="es-link">
                            <a href="javascript:void(0)" [routerLink]="['../manage-list']"
                                class="btn btn-outline-primary"><span><img src="assets/images/back.svg" alt=""
                                        class="plus-icon" /></span>Back to
                                List</a>
                        </div>
                    </div>
                    <div class="search-panel mb-3">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12">
                                <div class="flex-div align-items-start">
                                    <div>
                                        <label class="mb-2 crh">List Type<span class="star-color"> *</span></label>
                                        <div class="mt-2 list-type">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="listType"
                                                    (change)="onlistTypeChange('Dynamic')" formControlName="listType"
                                                    id="inlineRadio2" value="Dynamic" checked />
                                                <label class="form-check-label" for="inlineRadio2">Dynamic</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="listType"
                                                    (change)="onlistTypeChange('System-Default')"
                                                    formControlName="listType" id="inlineRadio3"
                                                    value="System-Default" />
                                                <label class="form-check-label"
                                                    for="inlineRadio3">System-Default</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="listType"
                                                    (change)="onlistTypeChange('Template')" formControlName="listType"
                                                    id="inlineRadio4" value="Template" />
                                                <label class="form-check-label" for="inlineRadio4">Template</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mx-4 listCtrl">
                                        <label class="mb-2 crh">List Name<span class="star-color"> *</span></label>
                                        <input (input)="checkBlankSpace($event)" class="form-control"
                                            formControlName="listName" placeholder="Enter the List Name"
                                            (focusout)="removeSpaces($event,f.listName)" />
                                        <div class="display-error" *ngIf="whitespace">
                                            <span>
                                                {{ _createListScreen.listName.pattern }}
                                            </span>

                                        </div>
                                        <div class="display-error" *ngIf="f.listName.invalid && isSubmitted">
                                            <div>
                                                <span *ngIf="f.listName.errors?.required">
                                                    {{ _createListScreen.listName.required }}
                                                </span>
                                                <span *ngIf="f.listName.errors?.maxlength">
                                                    {{ _createListScreen.listName.maxlength }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="listCtrl">
                                        <label class="mb-2" [ngClass]="{
                                            isDisabled: disableExpirationCalendar
                                          }">
                                            Expiration Date
                                        </label>

                                        <div class="date-control">
                                            <p-calendar [disabled]="disableExpirationCalendar" class="form-control"
                                                [readonlyInput]="true" [showIcon]="true" [iconDisplay]="'input'" [minDate]="minimumDate"
                                                placeholder="MM/DD/YYYY" formControlName="expirationDate"
                                                dateFormat="mm/dd/yy">
                                            </p-calendar>
                                        </div>
                                    </div> -->


                                    <div class="listCtrl mx-4">
                                        <input class="form-check-input mx-0 chkpos" type="checkbox"
                                            (change)="onTemplateCheck($event)">
                                        <label class="mb-2 ms-2">Create list using Template</label>

                                        <div class="mg-left">
                                            <select class="select-cs form-select" formControlName="template"
                                                (change)="onTemplateChange($event)">
                                                <option value="">Select</option>
                                                <option *ngFor="let temp of templateList" [ngValue]="temp.listId">
                                                    {{temp.listName}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="showError">
                        <div class="col-12">
                            <div class="alert alert-danger alert-dismissible fade show my-2" role="alert">
                                <ul class="view-error">
                                    <li *ngFor="let err of errorList">{{ err }}</li>
                                </ul>
                                <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                                    aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="grid-con1">
                        <div class="grid__wrapper wrp-pros grid-custom-height pt-0 m-0 mx-0">
                            <div class="accordion mb-3" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                            aria-expanded="false">
                                            <span class="filter-button me-3 mx-0" [ngClass]="{ actbtn: isFilter }"> <img
                                                    src="assets/images/filter.svg" alt="" class="flt-icon" /></span>
                                            Filter
                                            Whiteboard Activities
                                        </button>
                                    </h2>
                                    <div #filterDiv id="collapseThree" class="accordion-collapse collapse show"
                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body" *ngIf="filterBlock">

                                            <div class="display-error mb-2" *ngIf="invalidFilter == true">
                                                <span *ngIf="invalidFilter == true">
                                                    {{ _createListScreen.filterName.required }}
                                                </span>
                                            </div>
                                            <kendo-filter #filter [filters]="filters" [value]="filterValue">
                                            </kendo-filter>
                                            <div class="apply-filter-btn">
                                                <button kendoButton (click)="applyFilter(filter.value,isFilter1)">
                                                    Apply filter
                                                </button>
                                            </div>
                                        </div>
                                        <ng-template let-currentItem #templateForState>
                                            <kendo-multiselect [checkboxes]="true" [data]="statesList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true" textField="stateCode"
                                                valueField="stateCode" [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #template2>
                                            <kendo-multiselect [checkboxes]="true" [data]="businessLine"
                                                [ngModel]="getMultiselectValue(currentItem)"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true" textField="businessLineName"
                                                valueField="businessLineName" [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForContactState>
                                            <kendo-multiselect [checkboxes]="true" [data]="statesList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true" textField="stateCode"
                                                valueField="stateCode" [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForPolicy>
                                            <kendo-multiselect [checkboxes]="true" [data]="policyTypeList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true" textField="businessLineName"
                                                valueField="businessLineName"
                                                [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForProgress>
                                            <kendo-multiselect [checkboxes]="true" [data]="progress"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)" textField="progressName"
                                                valueField="progressName" [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateprofitCenter>
                                            <kendo-multiselect [checkboxes]="true" [data]="profitCenterData"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [ngModel]="getMultiselectValue(currentItem)"
                                                [valuePrimitive]="true" textField="stateCode" valueField="stateCode"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForClientStatus>
                                            <kendo-multiselect [checkboxes]="true" [data]="clientStatus"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true" textField="stateCode"
                                                valueField="stateCode" [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForRecallStatus>
                                            <kendo-multiselect [checkboxes]="true" [data]="recallForStatusList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForWbaStatus>
                                            <kendo-multiselect [checkboxes]="true" [data]="wbaForStatusList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForResult>
                                            <kendo-multiselect [checkboxes]="true" [data]="resultListForGrid"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)" textField="epicStatusName"
                                                valueField="epicStatusName" [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForWbaBussinessStatus>
                                            <kendo-multiselect [checkboxes]="true" [data]="businessStatusList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)"
                                                textField="businessStatusName" valueField="businessStatusName"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForPrimaryIndustry>
                                            <kendo-multiselect [checkboxes]="true" [data]="prospectIndustryList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValueByid(currentItem)"
                                                textField="prospectIndustryName" valueField="id"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForSecondaryIndustry>
                                            <kendo-multiselect [checkboxes]="true" [data]="prospectIndustryList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValueByid(currentItem)"
                                                textField="prospectIndustryName" valueField="id"
                                                [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForNumber>
                                            <kendo-numerictextbox [style.width.px]="170" format="n0"
                                                (valueChange)="onChangeForNumber($event,currentItem)"
                                                [value]="getNumericValue(currentItem)"></kendo-numerictextbox>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForNumberEXP>
                                            <kendo-numerictextbox [style.width.px]="170" format="n2"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [value]="getNumericValueEXP(currentItem)"></kendo-numerictextbox>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForNumberWithoutComma>
                                            <kendo-numerictextbox [style.width.px]="170" format="00"
                                                (valueChange)="onChangeForNumber($event,currentItem)"
                                                [value]="getNumericValue(currentItem)"></kendo-numerictextbox>
                                        </ng-template>
                                        <ng-template let-currentItem #templateForUserName>
                                            <kendo-multiselect [checkboxes]="true" [data]="userList"
                                                (valueChange)="onChangeForMultiselect($event,currentItem)"
                                                [autoClose]="false" [valuePrimitive]="true"
                                                [ngModel]="getMultiselectValue(currentItem)" textField="display_name"
                                                valueField="display_name" [ngModelOptions]="{standalone: true}">
                                            </kendo-multiselect>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="this.masterWBAList.length == 0 && hideShowGrid" class="mid-con mt-3">
                                {{filterMsg}}
                            </div>
                            <div *ngIf="isDataAvailable" class="mid-con mt-3">
                                <div class="list-content">

                                    <div class="list-md-con pt-0">

                                        <div class="row">
                                            <div class="grid-con1 ">
                                                <div class="grid__wrapper wrp-pros grid-custom-height pt-0 m-0 mx-0"
                                                    id="noaction">
                                                    <kendo-grid filterable="menu" [resizable]="true"
                                                        (filterChange)="gridFilterChange($event)" [data]="finalWBAList"
                                                        [sortable]="true" [sort]="sort"
                                                        (sortChange)="sortChange($event)" [skip]="skip"
                                                        [filter]="gridFilter" (pageChange)="whiteBoardPaginate($event)"
                                                        [pageable]="{ pageSizes: true, position: 'top' }"
                                                        [pageSize]="pageSize"
                                                        [sortable]="{allowUnsort: false,mode: false ? 'multiple' : 'single'}">
                                                        <ng-template kendoPagerTemplate let-totalPages="totalPages"
                                                            let-currentPage="currentPage">
                                                            <div class="flex-div justify-content-between grid-hd">
                                                                <div class="flex-div">
                                                                    <kendo-textbox
                                                                        placeholder="Search in all columns..."
                                                                        #searchText
                                                                        (change)="searchValue(searchText.value)"
                                                                        [style.width.px]="205">
                                                                    </kendo-textbox><img src="assets/images/search.svg"
                                                                        (click)="onSearch()" alt="" class="ms-2" />
                                                                </div>
                                                                <div class="flex-div">
                                                                    <div class="flex-div">
                                                                        <kendo-pager-prev-buttons>
                                                                        </kendo-pager-prev-buttons>
                                                                        <kendo-pager-numeric-buttons
                                                                            [buttonCount]="buttonCount">
                                                                        </kendo-pager-numeric-buttons>
                                                                        <kendo-pager-next-buttons>
                                                                        </kendo-pager-next-buttons>
                                                                        <kendo-pager-page-sizes [pageSizes]="sizes">
                                                                        </kendo-pager-page-sizes>
                                                                        <kendo-grid-messages pagerItems="Items"
                                                                            [pagerItemsPerPage]="'Items per page'">
                                                                        </kendo-grid-messages>
                                                                    </div>
                                                                    <kendo-pager-info class="bd-l"></kendo-pager-info>
                                                                </div>
                                                            </div>
                                                            <!-- <button [ngClass]="{ actbtn: isFilter }"
                                                                (click)="columnFilter(isFilter)" class="filter-button">
                                                                <img src="assets/images/filter.svg" alt=""
                                                                    class="flt-icon" />
                                                            </button> -->
                                                        </ng-template>
                                                        <kendo-grid-column field="companyName" [minResizableWidth]="510"
                                                            [maxResizableWidth]="800" title="Company Name"
                                                            [width]="530">
                                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                                <div class="cr-wd">
                                                                    <span href="javascript:void(0)" title=""
                                                                        pTooltip="{{dataItem.companyName}}"
                                                                        tooltipPosition="bottom"
                                                                        tooltipStyleClass="tooltipstyle">{{dataItem.companyName}}</span>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="masterBusinessLine"
                                                            [minResizableWidth]="160" [maxResizableWidth]="500"
                                                            title="Line" [width]="180">
                                                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column">
                                                                <kendo-multiselect [checkboxes]="true"
                                                                    [autoClose]="true" [data]="businessLine"
                                                                    [valuePrimitive]="true"
                                                                    [(ngModel)]="gridFilterArrayLob"
                                                                    [ngModel]="getMultiselectValueChange(gridFilterArrayLob)"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (valueChange)="onLineOfBusinessChange($event)"
                                                                    textField="businessLineName"
                                                                    valueField="businessLineName">
                                                                </kendo-multiselect>
                                                            </ng-template> -->
                                                            <ng-template kendoGridFilterMenuTemplate let-column="column"
                                                                let-filter="filter" let-filterService="filterService">
                                                                <multicheck-filter [data]="businessLine"
                                                                    textField="businessLineName"
                                                                    valueField="businessLineName"
                                                                    [currentFilter]="filter"
                                                                    [filterService]="filterService"
                                                                    [isPrimitive]="false"
                                                                    [field]="column.field"></multicheck-filter>
                                                            </ng-template>
                                                        </kendo-grid-column>

                                                        <kendo-grid-column [minResizableWidth]="180" field="policyType"
                                                            title="Policy Type" [width]="180">
                                                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                                                                <kendo-multiselect [checkboxes]="true"
                                                                    [(ngModel)]="gridFilterArrayPolicyType"
                                                                    [ngModel]="getMultiselectValueChange(gridFilterArrayPolicyType)"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    [autoClose]="true" [data]="policyTypeList"
                                                                    [valuePrimitive]="true" textField="businessLineName"
                                                                    valueField="businessLineName"
                                                                    (valueChange)="onPolicyTypeChange($event)">
                                                                </kendo-multiselect>
                                                            </ng-template> -->
                                                            <ng-template kendoGridFilterMenuTemplate let-column="column"
                                                                let-filter="filter" let-filterService="filterService">
                                                                <multicheck-filter [data]="policyTypeList"
                                                                    textField="businessLineName"
                                                                    valueField="businessLineName"
                                                                    [currentFilter]="filter"
                                                                    [filterService]="filterService"
                                                                    [isPrimitive]="false"
                                                                    [field]="column.field"></multicheck-filter>
                                                            </ng-template>
                                                        </kendo-grid-column>

                                                        <kendo-grid-column field="effectiveDate"
                                                            [minResizableWidth]="120" [maxResizableWidth]="500"
                                                            title="Effective Date" [width]="130" filter="date"
                                                            format="{0:d}">
                                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                                {{dataItem.effectiveDate | date:'MM/dd/yyyy'}}
                                                            </ng-template>
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <date-range-filter [field]="column.field"
                                                                    [filter]="filter" [filterService]="filterService">
                                                                </date-range-filter>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="masterBusinessStatus"
                                                            [minResizableWidth]="180" [maxResizableWidth]="500"
                                                            title="Business Status" [width]="200">
                                                            <!-- <ng-template kendoGridFilterMenuTemplate>
                                                                <kendo-multiselect [checkboxes]="true"
                                                                    [autoClose]="false" [data]="businessStatusList"
                                                                    [valuePrimitive]="true"
                                                                    [(ngModel)]="gridFilterArrayBussiness"
                                                                    [ngModel]="getMultiselectValueChange(gridFilterArrayBussiness)"
                                                                    (valueChange)="onBussinessStatusChange($event)"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    textField="businessStatusName"
                                                                    valueField="businessStatusName">
                                                                </kendo-multiselect>
                                                            </ng-template> -->
                                                            <ng-template kendoGridFilterMenuTemplate let-column="column"
                                                                let-filter="filter" let-filterService="filterService">
                                                                <multicheck-filter [data]="businessStatusList"
                                                                    textField="businessStatusName"
                                                                    valueField="businessStatusName"
                                                                    [currentFilter]="filter"
                                                                    [filterService]="filterService"
                                                                    [isPrimitive]="false"
                                                                    [field]="column.field"></multicheck-filter>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="contactName" title="Contact Person"
                                                            [minResizableWidth]="300" [maxResizableWidth]="500"
                                                            [width]="320">
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="contactNumber"
                                                            [minResizableWidth]="140" [maxResizableWidth]="500"
                                                            title="Contact Number" [width]="160">
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <kendo-grid-string-filter-menu [column]="column"
                                                                    [filter]="filter" [filterService]="filterService"
                                                                    operator="startswith">
                                                                </kendo-grid-string-filter-menu>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="acordStatus" [minResizableWidth]="140"
                                                            [maxResizableWidth]="500" title="ACORD Status"
                                                            [width]="160">
                                                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                                                                <kendo-multiselect [checkboxes]="true"
                                                                    [autoClose]="false" [data]="filterForAcordStatus"
                                                                    [valuePrimitive]="true"
                                                                    [(ngModel)]="gridFilterAccord"
                                                                    [ngModel]="getMultiselectValueChange(gridFilterAccord)"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (valueChange)="onAccordStatusChange($event)">
                                                                </kendo-multiselect>
                                                            </ng-template> -->
                                                            <ng-template kendoGridFilterMenuTemplate let-column="column"
                                                                let-filter="filter" let-filterService="filterService">
                                                                <multicheck-filter [data]="filterForAcordStatus"
                                                                    [currentFilter]="filter"
                                                                    [filterService]="filterService" [isPrimitive]="true"
                                                                    [field]="column.field"></multicheck-filter>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="progressName"
                                                            [minResizableWidth]="150" [maxResizableWidth]="500"
                                                            title="Progress" [width]="180">
                                                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                                                                <kendo-multiselect [checkboxes]="true"
                                                                    [autoClose]="false" [data]="progress"
                                                                    [valuePrimitive]="true"
                                                                    [(ngModel)]="gridFilterProgress"
                                                                    [ngModel]="getMultiselectValueChange(gridFilterProgress)"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (valueChange)="onProgressListChange($event)"
                                                                    textField="progressName" valueField="progressName">
                                                                </kendo-multiselect>
                                                            </ng-template> -->
                                                            <ng-template kendoGridFilterMenuTemplate let-column="column"
                                                                let-filter="filter" let-filterService="filterService">
                                                                <multicheck-filter [data]="progress"
                                                                    textField="progressName" valueField="progressName"
                                                                    [currentFilter]="filter"
                                                                    [filterService]="filterService"
                                                                    [isPrimitive]="false"
                                                                    [field]="column.field"></multicheck-filter>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="epicStatusName"
                                                            [minResizableWidth]="150" [maxResizableWidth]="500"
                                                            title="Result" [width]="180">
                                                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                                                                <kendo-multiselect [checkboxes]="true"
                                                                    [autoClose]="false" [data]="resultList1"
                                                                    [valuePrimitive]="true"
                                                                    [(ngModel)]="gridFilterResult"
                                                                    [ngModel]="getMultiselectValueChange(gridFilterResult)"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (valueChange)="onResultListChange($event)"
                                                                    textField="epicStatusName"
                                                                    valueField="epicStatusName">
                                                                </kendo-multiselect>
                                                            </ng-template> -->
                                                            <ng-template kendoGridFilterMenuTemplate let-column="column"
                                                                let-filter="filter" let-filterService="filterService">
                                                                <multicheck-filter [data]="resultList1"
                                                                    [currentFilter]="filter"
                                                                    [filterService]="filterService" [isPrimitive]="true"
                                                                    [field]="column.field"></multicheck-filter>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="boundRevenue"
                                                            [minResizableWidth]="180" [maxResizableWidth]="500"
                                                            class="text-right" title="Exp. Bound Revenue" [width]="200"
                                                            filter="numeric" format="{0:c0}">
                                                            <ng-template kendoGridFilterCellTemplate let-filter
                                                                let-column="column">
                                                                <!-- {{dataItem.boundRevenue | currency : 'USD' : '$'
                                                                :'1.0-0'}} -->
                                                                <kendo-grid-numeric-filter-cell [column]="column"
                                                                    [filter]="filter" [max]="100000000000" [min]="0"
                                                                    [spinners]="true" operator="gte">
                                                                    <kendo-filter-eq-operator></kendo-filter-eq-operator>
                                                                    <kendo-filter-neq-operator></kendo-filter-neq-operator>
                                                                    <kendo-filter-gte-operator></kendo-filter-gte-operator>
                                                                    <kendo-filter-gt-operator></kendo-filter-gt-operator>

                                                                    <kendo-filter-lte-operator>
                                                                    </kendo-filter-lte-operator>
                                                                    <kendo-filter-lt-operator></kendo-filter-lt-operator>
                                                                    <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
                                                                </kendo-grid-numeric-filter-cell>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column field="ownerUserName"
                                                            [minResizableWidth]="300" [maxResizableWidth]="500"
                                                            title="Owner" [width]="320">
                                                            <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                                                                <kendo-multiselect [checkboxes]="true"
                                                                    [autoClose]="false" [data]="userList"
                                                                    [valuePrimitive]="true"
                                                                    [(ngModel)]="gridOwnerWbaListArray"
                                                                    [ngModel]="getMultiselectValueChange(gridOwnerWbaListArray)"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (valueChange)="wbaOwnerListChange($event)"
                                                                    textField="display_name" valueField="display_name">
                                                                </kendo-multiselect>
                                                            </ng-template> -->
                                                            <ng-template kendoGridFilterMenuTemplate let-column="column"
                                                                let-filter="filter" let-filterService="filterService">
                                                                <multicheck-filter [data]="userList"
                                                                    textField="display_name" valueField="display_name"
                                                                    [currentFilter]="filter"
                                                                    [filterService]="filterService"
                                                                    [isPrimitive]="false"
                                                                    [field]="column.field"></multicheck-filter>
                                                            </ng-template>
                                                        </kendo-grid-column>


                                                        <!-- <kendo-grid-column field="ein" title="EIN" [width]="30">
                                                        <ng-template kendoGridCellTemplate let-dataItem>
                                                            {{dataItem.ein==0?'':dataItem.ein | mask:'00-0000000'}}
                                                        </ng-template>
                                                    </kendo-grid-column> -->

                                                        <kendo-grid-column field="wbaCreatedDate"
                                                            [minResizableWidth]="110" [maxResizableWidth]="500"
                                                            title="Created Date" [width]="130" filter="date"
                                                            format="{0:d}">
                                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                                {{dataItem.wbaCreatedDate
                                                                |
                                                                date:'MM/dd/yyyy'}}
                                                            </ng-template>
                                                            <ng-template kendoGridFilterMenuTemplate let-filter
                                                                let-column="column" let-filterService="filterService">
                                                                <date-range-filter [field]="column.field"
                                                                    [filter]="filter" [filterService]="filterService">
                                                                </date-range-filter>
                                                            </ng-template>
                                                        </kendo-grid-column>



                                                    </kendo-grid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="btn-blk" *ngIf="isDataAvailable || nextButtonVisiable">
                                <button style="visibility: hidden"></button>
                                <button *ngIf="!showSaveButton || nextButtonVisiable" class="btn btn-primary"
                                    (click)="sendData('create-whiteBoard-column-list')">Next</button>
                                <button *ngIf="showSaveButton" class="btn btn-primary"
                                    (click)="saveDataApiCall()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>

        <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
            [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
            [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
            <p>{{ _confirmBox.message }}</p>
            <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                <ng-container>
                    <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary">
                    </p-button>
                    <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
                </ng-container>
                <ng-container>
                    <p-button label="OK" styleClass="btn btn-primary"></p-button>
                    <p-button (click)="_confirmBox.isVisible = false" label="Cancel"
                        styleClass="btn btn-outline-primary">
                    </p-button>
                </ng-container>
            </ng-template>
        </p-dialog>
    </div>
</div>
<!-- Telrik implemented end -->
